import {useState} from 'react';
import CreateTemplatesCategoryModal from 'views/templates/categories/CreateTemplatesCategoryModal';

export default function CreateTemplatesCategory() {

  // states
  const [isOpen, setIsOpen] = useState(false)

  return(
    <>
      <button className="button h-button is-primary is-raised" onClick={() => setIsOpen(true)}>
        <span className="icon">
          <i aria-hidden="true" className="fas fa-plus"/>
        </span>
        <span>Add Category</span>
      </button>
      {isOpen && <CreateTemplatesCategoryModal setIsOpen={setIsOpen}/>}
    </>

  )
}
