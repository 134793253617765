
import {getProducts} from 'hooks/stripe';
import {useState} from 'react';
import PricesModal from 'views/products/PricesModal';

export default function ProductsIndex() {

  const products = getProducts();

  return(
    <div className="page-content-inner">
      <h1 className="title is-4 is-narrow">Grafixshop Plans</h1>
      <br />
      <div className="columns is-multiline">
        {products.map((product, index) => (
          <ProductCard product={product} key={index} />
        ))}
      </div>
    </div>
  )
}

function ProductCard({product}) {

  const [pricesModalIsOpen, setPricesModalIsOpen] = useState(false)

  return(
    <div className="column is-4">
      <div className="s-card-advanced h-card">
        <div className="card-head">
          <div className="left">
            <div className="media-flex-center no-margin">
              <div className="h-avatar">
                <img className="avatar" src={product.images[0]} alt=".." />
              </div>
              <div className="flex-meta">
                <span>{product.name}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="inner-content p-2 p-b-20">
            <p>{product.description}</p>
          </div>
          {Object.keys(product.metadata).map((key, index) => key !== 'firebaseRole' && (
            <div className="snack mx-2" key={index}>
              <div className="snack-media is-icon is-primary">
                <i className="snack-icon is-solid info-text" style={{fontSize: '1.1rem'}}>{product.metadata[key]}</i>
              </div>
              <span className="snack-text pr-3">{key}</span>
            </div>
          ))}
        </div>
        <div className="card-foot is-justify-content-end">
          <div className="right">
            <button className="button is-primary is-raised" onClick={() => setPricesModalIsOpen(true)}>Pricing</button>
          </div>
        </div>
      </div>
      {pricesModalIsOpen && <PricesModal product={product} setPricesModalIsOpen={setPricesModalIsOpen} />}
    </div>
  )
}
