import {useState} from 'react'
import {useAuth} from "contexts/AuthContext";
import {useSelector} from "react-redux";
import {selectTasksMetadata} from "slices/taskSlice";
import Task from "models/Task";
import Chart from "components/Chart";
import constants from "constants.js"
import {Link} from "react-router-dom";
import Calendar from 'react-calendar';
import 'assets/scss/calendar.scss';

export default function Insights() {

  const { currentUser } = useAuth()

  const stats = useSelector(selectTasksMetadata)

  const themeColors = constants.themeColors;

  const chartOptions = {
    chart: {
      sparkline: {
        enabled: true
      },
      toolbar: {
        show: false
      }
    },
    colors: [themeColors.accent, themeColors.secondary, themeColors.orange, themeColors.purple, themeColors.info],
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        track: {
          background: "#e7e7e7",
          strokeWidth: '97%',
          margin: 5,
          dropShadow: {
            enabled: false,
            top: 2,
            left: 0,
            color: '#999',
            opacity: 1,
            blur: 2
          }
        },
        dataLabels: {
          name: {
            show: false
          },
          value: {
            offsetY: -2,
            fontSize: '22px'
          }
        }
      }
    },
    grid: {
      padding: {
        top: 80
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'light',
        shadeIntensity: 0.1,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 53, 91]
      },
    }
  }

  const donutChartOptions = {
    labels: [Task.STATUS.PENDING, Task.STATUS.MISSING_INFORMATION, Task.STATUS.APPROVED,
      Task.STATUS.IN_PROGRESS, Task.STATUS.UPLOADED, Task.STATUS.DONE],
    colors: ['#ffe57f', '#ff3860', '#23d160', '#ffa981', '#209cee', '#00d1b2'],
  };

  const donutChartSeries = [stats[Task.STATUS.PENDING], stats[Task.STATUS.MISSING_INFORMATION],
    stats[Task.STATUS.APPROVED], stats[Task.STATUS.IN_PROGRESS], stats[Task.STATUS.UPLOADED], stats[Task.STATUS.DONE]]

  return(
    <div className="page-content-inner">
      <div className="personal-dashboard personal-dashboard-v1">
        <div className="dashboard-header">
          <div className="h-avatar is-large">
            <img className="avatar" src={currentUser.photoURL} alt="" />
          </div>
          <div className="start">
            <h3>Welcome back, {currentUser.displayName}</h3>
            <p>We're very happy to see you again on your personal dashboard.</p>
          </div>
          <div className="end">
            <Link to={'calendar'} className="button h-button is-dark-outlined">View Calendar</Link>
            <Link to={'tasks'} className="button h-button is-primary is-elevated">Manage Tasks</Link>
          </div>
        </div>
        <div className="dashboard-body">
          <div className="columns is-multiline">

            <div className="column is-6">
              <div className="dashboard-card">
                <h4 className="dark-inverted">Your Quick Stats</h4>

                <div className="quick-stats">
                  <div className="quick-stats-inner">
                    <div className="quick-stat">
                      <div className="media-flex-center">
                        <div className="h-icon is-purple is-rounded">
                          <i className="lnil lnil-alarm-clock"/>
                        </div>
                        <div className="flex-meta">
                          <span>{stats[Task.STATUS.PENDING]}</span>
                          <span>Pending Tasks</span>
                        </div>
                      </div>
                    </div>
                    <div className="quick-stat">
                      <div className="media-flex-center">
                        <div className="h-icon is-orange is-rounded">
                          <i className="lnil lnil-leaf-alt"/>
                        </div>
                        <div className="flex-meta">
                          <span>{stats[Task.STATUS.IN_PROGRESS]}</span>
                          <span>In Progress Tasks</span>
                        </div>
                      </div>
                    </div>

                    <div className="quick-stat">
                      <div className="media-flex-center">
                        <div className="h-icon is-green is-rounded">
                          <i className="lnil lnil-checkmark-circle" />
                        </div>
                        <div className="flex-meta">
                          <span>{stats[Task.STATUS.DONE]}</span>
                          <span>Done Tasks</span>
                        </div>
                      </div>
                    </div>
                    <div className="quick-stat">
                      <div className="media-flex-center">
                        <div className="h-icon is-info is-rounded">
                          <i className="lnil lnil-layers"/>
                        </div>
                        <div className="flex-meta">
                          <span>{stats.total}</span>
                          <span>Total Tasks</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="column is-6">
              <div className="dashboard-card" style={{position: 'relative'}}>
                <Chart type={'donut'} series={donutChartSeries} height={345} title={'Tasks Insights'} options={donutChartOptions}/>
                <div className="resize-triggers">
                  <div className="expand-trigger">
                    <div style={{width: '549px', height: '351px'}}/>
                  </div>
                  <div className="contract-trigger"/>
                </div>
              </div>
            </div>

            <div className="column is-4">
              <div className="dashboard-card is-upgrade">
                <i className="lnil lnil-crown-alt-1"/>
                <div className="cta-content">
                  <h4>Hey {currentUser.displayName}, you're doing great.</h4>
                  <p className="white-text">Start using our tasks management applications</p>
                </div>
              </div>
            </div>

            <div className="column is-4">
              <div className="dashboard-card is-gauge">
                <div className="people">
                  <div className="h-avatar">
                    <img className="avatar" src="https://via.placeholder.com/150x150" alt={'...'} data-user-popover="19" />
                  </div>
                  <div className="h-avatar">
                    <span className="avatar is-fake is-h-purple" data-user-popover="35">
                      <span>SC</span>
                    </span>
                  </div>
                  <div className="h-avatar">
                    <img className="avatar" src="https://via.placeholder.com/150x150" alt="" data-user-popover="33" />
                  </div>
                </div>
                <div id="team-gauge" style={{minHeight: '225px'}}>
                  <Chart type={'radialBar'} series={[70]} height={345} title={'Tasks Insights'} options={chartOptions}/>
                </div>
                <div className="resize-triggers">
                  <div className="expand-trigger">
                    <div style={{width: '358px', height: '266px'}}/>
                  </div>
                  <div className="contract-trigger"/>
                </div>
              </div>
            </div>

            <div className="column is-4">
              <CalenderWidget />
            </div>

          </div>
        </div>
      </div>

    </div>
  )
}

function CalenderWidget() {
  const date = new Date();
  const currentYear = date.getFullYear();
  const currentMonth = date.toLocaleDateString('default', {
    month: "long"
  })

  const [value, onChange] = useState(new Date());

  return(
    <div className="widget picker-widget">
      <div className="widget-toolbar">
        <div className="left" />
        <div className="center">
          <h3>{currentMonth} {currentYear}</h3>
        </div>
        <div className="right" />
      </div>

      <Calendar
        nextLabel={null}
        next2Label={null}
        prevLabel={null}
        prev2Label={null}
        showNeighboringMonth={false}
        showNavigation={false}
        className={'calendar'}
        onChange={onChange}
        value={value}
      />
    </div>
  )
}
