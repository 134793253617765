import {formatDate} from 'helpers';
import {useState} from "react";
import truncate from "truncate";
import UpdateTask from "views/tasks/Update"
import ShowTask from "views/tasks/Show"
import useDropdown from "hooks/useDropdown";
import FeatherIcon from "feather-icons-react";
import moment from 'moment';

export default function TaskRow({task}) {


  const [dropdownIsOpen, toggleDropdown] = useDropdown()
  const [showIsOpen, setShowIsOpen] = useState(false)
  const [updateIsOpen, setUpdateIsOpen] = useState(false)

  return(
    <tr style={{
      backgroundColor: moment(task.creation_date).isAfter(moment().endOf('month')) ? 'aliceblue' : '#fff'
    }}>
      <td>{task.order}</td>
      <td>{formatDate(task.creation_date)}</td>
      <td dir="auto">{truncate(task.title, 100)}</td>
      <td dir="auto"><a href={'#'} onClick={() => setShowIsOpen(true)}>{truncate(task.content, 100)}</a></td>
      <td>{task.status}</td>
      <td className="is-end">
        <div>
          <div onClick={toggleDropdown}
               className={`dropdown is-spaced is-dots is-right dropdown-trigger is-pushed-mobile ${dropdownIsOpen && 'is-active'}`}>
            <div className="is-trigger" aria-haspopup="true">
              <FeatherIcon icon={'more-vertical'} />
            </div>
            <div className="dropdown-menu" role="menu">
              <div className="dropdown-content">
                <a href={"#"} className="dropdown-item is-media" onClick={() => setShowIsOpen(true)}>
                  <div className="icon">
                    <i className="lnil lnil-eye"/>
                  </div>
                  <div className="meta">
                    <span>View</span>
                    <span>View task details</span>
                  </div>
                </a>
                <a href={"#"} className="dropdown-item is-media" onClick={() => setUpdateIsOpen(true)}>
                  <div className="icon">
                    <i className="lnil lnil-pencil-alt"/>
                  </div>
                  <div className="meta">
                    <span>Edit</span>
                    <span>Edit task details</span>
                  </div>
                </a>
                {/* TODO: Might add this later */}
                {/*<hr className="dropdown-divider" />*/}
                {/*<a href="#" className="dropdown-item is-media">*/}
                {/*  <div className="icon">*/}
                {/*    <i className="lnil lnil-trash-can-alt"/>*/}
                {/*  </div>*/}
                {/*  <div className="meta">*/}
                {/*    <span>Delete</span>*/}
                {/*    <span>Remove task from list</span>*/}
                {/*  </div>*/}
                {/*</a>*/}
              </div>
            </div>
          </div>
        </div>
      </td>
      <td>
        {showIsOpen && <ShowTask task={task} setIsOpen={setShowIsOpen}/>}
        {updateIsOpen && <UpdateTask task={task} setIsOpen={setUpdateIsOpen}/>}
      </td>
    </tr>
  )
}
