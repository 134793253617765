import FeatherIcon from "feather-icons-react";
import {useButtonListener} from 'hooks/utils';

export default function ShowTemplate({ template, setIsOpen, setCreateIsOpen}) {

  const handleCreateButton = () => {
    setCreateIsOpen(true)
    setIsOpen(false)
  }

  useButtonListener(() => setIsOpen(false), 27)

  return(
    <div className="modal h-modal is-big is-active">
      <div className="modal-background  h-modal-close"/>
      <div className="modal-content">
        <div className="modal-card">
          <header className="modal-card-head">
            <h3>Template overview</h3>
            <button className="h-modal-close ml-auto" aria-label="close" onClick={() => setIsOpen(false)}>
              <FeatherIcon icon={'x'} />
            </button>
          </header>
          <div className="modal-card-body">
            <div className="inner-content">
              <table className="table is-hoverable is-fullwidth">
                <tbody>
                <tr>
                  <td>Title</td>
                  <td className='is-end' dir="auto">{template.title}</td>
                </tr>
                <tr>
                  <td>Content</td>
                  <td className='is-end' dir="auto"><p>{template.content}</p></td>
                </tr>
                <tr>
                  <td>Design Sizes</td>
                  <td className='is-end' dir="auto">
                    <ul>
                      {template.design_sizes.map((size, index) => <li key={index}>- {size}</li>)}
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>Notes</td>
                  <td className='is-end' dir="auto"><p>{template.notes}</p></td>
                </tr>
                <tr>
                  <td>Folder Name</td>
                  <td className='is-end'>{template.folder_name}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="modal-card-foot is-end">
            <a href={'#'} className="button h-button is-raised is-rounded" onClick={handleCreateButton}>
              Create
            </a>
            <a href={'#'} className="button h-button is-primary is-raised is-rounded" onClick={() => setIsOpen(false)}>
              Close
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
