import ApexChart from "react-apexcharts";

export default function Chart({type = 'bar', series, height, title, options}) {

  const data = {
    ...{
      height: height,
      type: type,
      series: series
    },
    options: {
      ...options,
      ...{
        title: {
          text: title
        },
      }
    }
  }

  return (
    <>
      <ApexChart {...data} />
    </>
  )
}
