import Gallery from 'components/Gallery';
import FeatherIcon from "feather-icons-react";
import {formatDate} from 'helpers';
import "assets/scss/custom.scss"
import {useButtonListener} from 'hooks/utils';


export default function Show({ task, setIsOpen}) {

  useButtonListener(() => setIsOpen(false), 27)

  return(
    <div className="modal h-modal is-big is-active">
      <div className="modal-background  h-modal-close"/>
      <div className="modal-content">
        <div className="modal-card">
          <header className="modal-card-head">
            <h3>Task overview</h3>
            <button className="h-modal-close ml-auto" aria-label="close" onClick={() => setIsOpen(false)}>
              <FeatherIcon icon={'x'} />
            </button>
          </header>
          <div className="modal-card-body">
            <div className="inner-content">
              <table className="table is-hoverable is-fullwidth">
                <tbody>
                <tr>
                  <td>ID</td>
                  <td className='is-end'>{task.order}</td>
                </tr>
                <tr>
                  <td>Creation Date</td>
                  <td className='is-end'>{formatDate(task.creation_date)}</td>
                </tr>
                <tr>
                  <td>Title</td>
                  <td className='is-end' dir="auto">{task.title}</td>
                </tr>
                <tr>
                  <td>Content</td>
                  <td className='is-end' dir="auto"><p>{task.content}</p></td>
                </tr>
                <tr>
                  <td>Design Sizes</td>
                  <td className='is-end' dir="auto">
                    <ul>
                      {task.design_sizes.map((size, index) => <li key={index}>- {size}</li>)}
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>Notes</td>
                  <td className='is-end' dir="auto"><p>{task.notes}</p></td>
                </tr>
                <tr>
                  <td>Folder Name</td>
                  <td className='is-end'>{task.folder_name}</td>
                </tr>
                <tr>
                  <td>Status</td>
                  <td className='is-end'>{task.status}</td>
                </tr>
                {task.imageURL ?
                  <>
                    <tr>
                      <td colSpan={2} className="align-text-center">Preview</td>
                    </tr>
                    <tr>
                      <td colSpan="2"><Gallery images={[task.imageURL]}/></td>
                    </tr>
                  </> : <tr />}
                </tbody>
              </table>
            </div>
          </div>
          <div className="modal-card-foot is-end">
            <a href={'#'} className="button h-button is-primary is-raised is-rounded" onClick={() => setIsOpen(false)}>
              Confirm
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
