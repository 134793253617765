// noinspection JSCheckFunctionSignatures

import constants from 'constants.js';
import taskConverter from 'converters/taskConverter';
import templateConverter from 'converters/templateConverter';

// noinspection ES6CheckImport
import {collection, query, where, onSnapshot, orderBy, getDocs} from "firebase/firestore";
import { createSlice, createSelector } from '@reduxjs/toolkit'
import {firestore} from "firebase.js";
import Task from "models/Task";
import moment from 'moment';


// =========================== Slice ===========================
export const taskSlice = createSlice({
  name: 'task',
  initialState: {
    tasks: [],
    templates: [],
    metadata: {
      total: 0,
      [Task.STATUS.PENDING]: 0,
      [Task.STATUS.MISSING_INFORMATION]: 0,
      [Task.STATUS.REJECTED]: 0,
      [Task.STATUS.APPROVED]: 0,
      [Task.STATUS.IN_PROGRESS]: 0,
      [Task.STATUS.UPLOADED]: 0,
      [Task.STATUS.DONE]: 0
    },
    monthStats: {
      total: 0,
      [Task.STATUS.PENDING]: 0,
      [Task.STATUS.MISSING_INFORMATION]: 0,
      [Task.STATUS.REJECTED]: 0,
      [Task.STATUS.APPROVED]: 0,
      [Task.STATUS.IN_PROGRESS]: 0,
      [Task.STATUS.UPLOADED]: 0,
      [Task.STATUS.DONE]: 0
    }
  },
  reducers: {
    set: (state, action) => {
      state.tasks = action.payload
    },
    remove: (state, action) => {
      state.tasks.filter((task) => task.content !== action.payload)
    },
    setTemplates: (state, action) => {
      state.templates = action.payload
    },
    setMetadata: (state, action) => {
      state.metadata = action.payload
    },
    setMonthStats: (state, action) => {
      state.monthStats = action.payload
    }
  },
})

// ============================= Actions =============================
export const { set, remove, setTemplates, setMetadata, setMonthStats } = taskSlice.actions

// ============================= Thunks =============================
export const fetchTasksMetaData = (client_id) => async dispatch => {
  const q = query(collection(firestore, 'tasks').withConverter(taskConverter),
    where('client_id', '==', client_id))
  const querySnapshot = await getDocs(q);
  const metadata = initializeMetadata()
  metadata['total'] = querySnapshot.size;
  querySnapshot.forEach((doc) => {
    metadata[doc.data().status]++
  })
  dispatch(setMetadata(metadata))
}

export const fetchTasks = (client_id, filters = []) => async dispatch => {
  filters.push(where('client_id', '==', client_id))
  const q = query(collection(firestore, 'tasks').withConverter(taskConverter), ...filters,
    orderBy('order', 'desc'))
  onSnapshot(q, (querySnapshot) => {
    const tasks = [];
    querySnapshot.forEach((doc) => {
      tasks.push({...doc.data(), ...{id: doc.id}})
    });
    dispatch(set(tasks))
  })
};

export const fetchCalendarData = (clientLink) => async () => {
  const tasks = await fetchClientTasks(clientLink)
  // TODO: make this dynamic
  const currentYear = 2022;
  const monthStatsObj = constants.MONTHS.reduce( (obj, month) =>
    Object.assign(obj, {[month]: Object.values(Task.STATUS).reduce( (o, k) =>
        Object.assign(o, {...{[k]: 0}, ...{Total: 0}}), {})}), {})
  tasks.forEach( task => {
    const month = task.creation_date.toLocaleString('default', { month: 'long' });
    if(task.creation_date.getFullYear() === currentYear) {
      monthStatsObj[month][task.status]++;
      monthStatsObj[month]['Total']++;
    }
  })
  return monthStatsObj;
}

export const fetchMonthStats = (client_id) => async dispatch => {
  const q = query(collection(firestore, 'tasks').withConverter(taskConverter),
    where('client_id', '==', client_id),
    where("creation_date", '>=', moment().startOf('month').toDate()),
    where('creation_date', '<', moment().add(1, 'M').startOf('month').toDate()),
  )
  onSnapshot(q, (querySnapshot) => {
    const metadata = initializeMetadata()
    metadata['total'] = querySnapshot.size;
    querySnapshot.forEach((doc) => {
      metadata[doc.data().status]++
    });
    dispatch(setMonthStats(metadata))
  })
}

export const fetchTemplates = (clientLink, filters = []) => async dispatch => {
  const q = query(collection(firestore, `users/${clientLink}/templates`).withConverter(templateConverter), ...filters)
  onSnapshot(q, (querySnapshot) => {
    const tasks = [];
    querySnapshot.forEach((doc) => {
      tasks.push({...doc.data(), ...{id: doc.id}})
    });
    dispatch(setTemplates(tasks))
  })
};

export const searchTasksByIds = (ids, client_id) => async dispatch => {
  const q = query(collection(firestore, 'tasks').withConverter(taskConverter),
    where('client_id', '==', client_id),
    orderBy('order', 'desc'))
  onSnapshot(q, (querySnapshot) => {
    const tasks = [];
    querySnapshot.forEach((doc) => {
      if(ids.includes(doc.id)) {
        tasks.push({...doc.data(), ...{id: doc.id}})
      }
    });
    dispatch(set(tasks))
  })
}

// ============================ Selectors ============================
export const selectTasks = state => state.task.tasks;
export const selectTemplates = state => state.task.templates;
export const selectTasksMetadata = state => state.task.metadata;
export const selectMonthStats = state => state.task.monthStats;

export const selectTasksFiltered = createSelector(selectTasks, (tasks) => {
  const filtered = initializeFiltered()
  filtered['All'] = tasks;
  tasks.forEach(task => {
    filtered[task.status].push(task)
  })
  return filtered;
})


// =========================== Default Export =========================
export default taskSlice.reducer

// =========================== Helper Methods =========================
function initializeFiltered() {
  return {
    All: [], [Task.STATUS.PENDING]: [], [Task.STATUS.MISSING_INFORMATION]: [], [Task.STATUS.REJECTED]: [],
    [Task.STATUS.APPROVED]: [], [Task.STATUS.IN_PROGRESS]: [], [Task.STATUS.UPLOADED]: [], [Task.STATUS.DONE]: []
  }
}

function initializeMetadata() {
  return {
    total: 0, [Task.STATUS.PENDING]: 0, [Task.STATUS.MISSING_INFORMATION]: 0, [Task.STATUS.REJECTED]: 0,
    [Task.STATUS.APPROVED]: 0, [Task.STATUS.IN_PROGRESS]: 0, [Task.STATUS.UPLOADED]: 0, [Task.STATUS.DONE]: 0
  }
}

async function fetchClientTasks(client_id) {
  const q = query(collection(firestore, 'tasks').withConverter(taskConverter),
    where('client_id', '==', client_id))
  const querySnapshot = await getDocs(q)
  const tasks = [];
  querySnapshot.forEach((doc) => {
    tasks.push({...doc.data(), ...{id: doc.id}})
  })
  return tasks;
}
