import {useAuth} from "contexts/AuthContext";
import {useHistory} from "react-router-dom";
import {useForm} from "hooks/utils";
import FeatherIcon from "feather-icons-react";
import Client from "models/Client";
import {useImageInput} from "hooks/utils";
import {useState} from "react";


export default function EditProfile() {
  const { currentUser, updateUserProfile, updateUserPassword, uploadProfileImage } = useAuth()
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const {register, handleSubmit, reset} = useForm(onSubmit)
  const [handleImageChange, imagePreviewUrl, image] = useImageInput()

  // Methods
  async function onSubmit(data) {
    try {
      setLoading(true)
      const client = new Client(data)
      await client.validate()
      const profileData = {}
      if(image) profileData.photoURL = await uploadProfileImage(image)
      if(data.displayName) profileData.displayName = data.displayName
      if(image || data.displayName) {
        await updateUserProfile(profileData)
      }

      // Update Password if exists Name
      if(data.password) {
        await updateUserPassword(data.password)
      }
      await reset()
      setLoading(false)
      return setTimeout(() => history.push('/coordinate'), 1000)
    } catch (err) {
      setLoading(false)
      await swal('Warning', err.message, 'warning')
    }
  }

  function loadingButtonClasses() {
    return loading && 'is-loading no-click'
  }

  function handleCancel(e) {
    e.preventDefault()
    setLoading(true)
    if(confirm("Are you sure you want to cancel the changes?")) {
      reset()
      return setTimeout(() => history.push('/client'), 1000)
    } else {
      setLoading(false)
    }
  }

  return(
    <div className="page-content-inner">
      <div className="account-wrapper">
        <div className="columns">
          <div className="column is-4">
            <div className="account-box is-navigation">
              <div className="media-flex-center">
                <div className="h-avatar is-large">
                  <img className="avatar" src={currentUser.photoURL} alt="" />
                </div>
                <div className="flex-meta">
                  <span>{currentUser.displayName}</span>
                  <span>{currentUser.data.role}</span>
                </div>
              </div>
              <div className="account-menu">
                <a href={'#'} className="account-menu-item is-active">
                  <i className="lnil lnil-user-alt"/>
                  <span>General</span>
                  <span className="end">
                    <i aria-hidden="true" className="fas fa-arrow-right"/>
                  </span>
                </a>
              </div>
            </div>
          </div>

          <div className="column is-8">
            <form className="account-box is-form is-footerless" onSubmit={handleSubmit} autoComplete="off">
              <div className="form-head stuck-header">
                <div className="form-head-inner">
                  <div className="left">
                    <h3>General Info</h3>
                    <p className="info-text">Edit your account's general information</p>
                  </div>
                  <div className="right">
                    <div className="buttons">
                      <a href={`#`} className={`button h-button is-light is-dark-outlined ${loadingButtonClasses()}`}
                         onClick={handleCancel}>
                        <span className="icon">
                          <i className="lnir lnir-arrow-left rem-100"/>
                        </span>
                        <span>Go Back</span>
                      </a>
                      <button id="save-button"
                              className={`button h-button is-primary is-raised ${loadingButtonClasses()}`}>
                        Save Changes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`form-body has-loader ${loading && 'has-loader-active'}`}>
                <div className="h-loader-wrapper">
                  <div className="loader is-small is-loading"/>
                </div>
                <div className="fieldset">
                  <div className="fieldset-heading">
                    <h4>Profile Picture</h4>
                    <p className="info-text">This is how others will recognize you</p>
                  </div>
                  <div className="h-avatar profile-h-avatar is-xl">
                    <img className="avatar" src={imagePreviewUrl || currentUser.photoURL} alt="profile" />
                    <label htmlFor={'photoURL'} className="button is-circle edit-button is-edit">
                      <span className="icon is-small">
                        <FeatherIcon icon={'edit-2'} />
                      </span>
                    </label>
                    <input id={'photoURL'} type={'file'} style={{display: 'none'}} onChange={handleImageChange}/>
                  </div>
                </div>

                <div className="fieldset">
                  <div className="fieldset-heading">
                    <h4>Personal Info</h4>
                    <p className="info-text">Fill your information here</p>
                  </div>


                  <div className="columns is-multiline">
                    <div className="column is-12">
                      <div className="field">
                        <div className="control has-icon">
                          <input type="text" className="input" placeholder="Display Name" {...register('displayName')} defaultValue={currentUser.displayName} />
                          <div className="form-icon">
                            <FeatherIcon icon={'user'} />
                          </div>
                          <p className="info-text">This is your profile name</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="fieldset">
                  <div className="fieldset-heading">
                    <h4>Change Password</h4>
                    <p className="info-text">Make sure the password confirmation matches the Password</p>
                  </div>


                  <div className="columns is-multiline">
                    <div className="column is-12">
                      <div className="field">
                        <div className="control has-icon">
                          <input type="password" className="input" placeholder="Password" {...register('password')}/>
                          <div className="form-icon">
                            <FeatherIcon icon={'lock'} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="column is-12">
                      <div className="field">
                        <div className="control has-icon">
                          <input type="password" className="input" placeholder="Confirm Password" {...register('confirm_password')} />
                          <div className="form-icon">
                            <FeatherIcon icon={'lock'} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </form>
          </div>

        </div>
      </div>

    </div>
  )
}
