import {formatDate} from 'helpers.js';
import useDropdown from "hooks/useDropdown";
import truncate from 'truncate';
import UpdateTask from "views/tasks/Update";
import ShowTask from "views/tasks/Show";
import {useState} from "react";
import FeatherIcon from "feather-icons-react";
import moment from 'moment';

export default function KanbanCard({task, isInitial}) {

  const [showIsOpen, setShowIsOpen] = useState(false)
  const [updateIsOpen, setUpdateIsOpen] = useState(false)
  const [dropdownIsOpen, toggleDropdown] = useDropdown()

  const createdAt = moment(task.created_at);

  return(
    <div className="kanban-card" style={{
      backgroundColor: moment(task.creation_date).isAfter(moment().endOf('month')) ? 'antiquewhite' : '#fff'
    }}>
      <div className="card-body">
        <div className="is-flex is-justify-content-space-between" onClick={() => setShowIsOpen(true)}>
          <h6 className="card-title is-size-7">Task # : {task.order}</h6>
          <div>
            <p className="is-size-7">{createdAt.format("DD/MM/YYYY")}</p>
            <p className="is-size-7 has-text-right">{createdAt.format("hh:mm A")}</p>
          </div>
        </div>
        <h4 className="card-title" dir="auto" onClick={() => setShowIsOpen(true)}>{truncate(task.content, 60)}</h4>
        <div className="kanban-card-stats">
          <span onClick={() => setShowIsOpen(true)}>
            <FeatherIcon icon={'clock'} />
            {formatDate(task.creation_date)}
          </span>
          <div className="avatar-stack">
            <div className="is-end">
              <div>
                <div onClick={toggleDropdown}
                     className={`dropdown is-spaced is-dots ${isInitial ? 'is-left' : 'is-right'} dropdown-trigger is-pushed-mobile ${dropdownIsOpen && 'is-active'}`}>
                  <div className="is-trigger" aria-haspopup="true">
                    <FeatherIcon icon={'more-vertical'}/>
                  </div>
                  <div className="dropdown-menu" role="menu" style={{top: 'unset', bottom: '100%'}}>
                    <div className="dropdown-content">
                      <a href={"#"} className="dropdown-item is-media" onClick={() => setShowIsOpen(true)}>
                        <div className="icon">
                          <i className="lnil lnil-eye"/>
                        </div>
                        <div className="meta">
                          <span>View</span>
                          <span>View task details</span>
                        </div>
                      </a>
                      <a href={"#"} className="dropdown-item is-media" onClick={() => setUpdateIsOpen(true)}>
                        <div className="icon">
                          <i className="lnil lnil-pencil-alt"/>
                        </div>
                        <div className="meta">
                          <span>Edit</span>
                          <span>Edit task details</span>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showIsOpen && <ShowTask isOpen={showIsOpen} task={task} setIsOpen={setShowIsOpen}/>}
      {updateIsOpen && <UpdateTask isOpen={updateIsOpen} task={task} setIsOpen={setUpdateIsOpen}/>}
    </div>
  )
}
