import {useAuth} from 'contexts/AuthContext';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {fetchCategories, selectCategories} from 'slices/templatesCategorySlice';
import CategoryCard from 'views/templates/categories/CategoryCard';
import CreateCategory from 'views/templates/categories/CreateTemplatesCategory';

export default function Index() {

  const dispatch = useDispatch()
  const {currentUser} = useAuth()

  useEffect(() => {
    dispatch(fetchCategories(currentUser.uid))
  }, [])

  let categories = useSelector(selectCategories)

  return(
    <div className="page-content-inner">
      <div className="standard-onboarding">
        <div className="is-flex">
          <div className="buttons ml-auto">
            <CreateCategory />
          </div>
        </div>

        <div className="onboarding-wrap">
          <div className="onboarding-wrap-inner columns is-multiline is-mobile">
            <div className="onboarding-card column is-4 is-flex is-flex-direction-column is-justify-content-space-between">
              <div>
                <img className="light-image category-icon" src={require("assets/img/illustrations/icons/Information.svg").default} alt="" />
                <img className="dark-image category-icon" src={require("assets/img/illustrations/icons/Information.svg").default} alt=""/>
              </div>
              <div>
                <h3>Uncategorized</h3>
                <p>Show uncategorized templates</p>
                <div className="button-wrap">
                  <Link to={'templates'} className="button h-button is-primary is-outlined is-rounded is-raised">
                    Show
                  </Link>
                </div>
              </div>
            </div>
            {categories.map((category, index) => (
              <CategoryCard category={category} key={index} />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
