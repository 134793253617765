import {useAuth} from 'contexts/AuthContext';
import FeatherIcon from 'feather-icons-react';
import useDropdown from 'hooks/useDropdown';
import {useState} from 'react';
import {Link} from 'react-router-dom';
import truncate from 'truncate';
import Update from 'views/templates/categories/Update';
import {doc, deleteDoc} from "firebase/firestore";
import {useFirestore} from "reactfire";

export default function CategoryCard({category}) {

  const firestore = useFirestore()
  const {currentUser} = useAuth()

  const [dropdownIsOpen, toggleDropdown] = useDropdown()
  const [updateIsOpen, setUpdateIsOpen] = useState(false)

  const handleDelete = async (ev) => {
    ev.preventDefault()
    if (!confirm('Are you sure you want to delete this category?')) return;
    await deleteDoc(doc(firestore, `users/${currentUser.uid}/templates_categories`, category.id))
  }

  return(
    <>
      <div className="onboarding-card column is-4 is-flex is-flex-direction-column is-justify-content-space-between">
        <div>
          <div className="card-head is-flex is-justify-content-end">
            <div className={`dropdown is-spaced is-dots is-right dropdown-trigger is-down ${dropdownIsOpen && 'is-active'}`}>
              <div className="is-trigger" aria-haspopup="true" onClick={toggleDropdown}>
                <FeatherIcon icon={'more-vertical'} />
              </div>
              <div className="dropdown-menu" role="menu">
                <div className="dropdown-content">
                  <a href="#" className="dropdown-item is-media" onClick={() => setUpdateIsOpen(true)}>
                    <div className="icon">
                      <i className="lnil lnil-pencil-alt"/>
                    </div>
                    <div className="meta">
                      <span>Edit</span>
                      <span>Update the category</span>
                    </div>
                  </a>
                  <a href="#" className="dropdown-item is-media" onClick={handleDelete}>
                    <div className="icon">
                      <i className="lnil lnil-trash"/>
                    </div>
                    <div className="meta">
                      <span>Delete</span>
                      <span>Delete the category</span>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <img className="light-image category-icon" src={category.imageURL} alt="" />
          <img className="dark-image category-icon" src={category.imageURL} alt=""/>
        </div>
        <div>
          <h3>{truncate(category.title, 30)}</h3>
          <p>{truncate(category.description, 30)}</p>
          <div className="button-wrap">
            <Link to={`templates/${category.id}`} className="button h-button is-primary is-outlined is-rounded is-raised">
              Show
            </Link>
          </div>
        </div>
      </div>
      {updateIsOpen && <Update category={category} setIsOpen={setUpdateIsOpen} />}
    </>
  )
}
