import {firestore} from 'firebase.js';
import {doc} from 'firebase/firestore';
import Task from 'models/Task';

const taskConverter = {
  toFirestore: (data) => {
    const convertedData =  {...data, ...{
        creation_date: new Date(data?.creation_date),
        created_at: data?.created_at ? new Date(data?.created_at) : new Date()
      }
    }
    const task = new Task(convertedData)
    task.validate()
    return task.data;
  },
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    return {
      ...data, ...{
        creation_date: data.creation_date?.toDate(),
        created_at: data.created_at?.toDate()
      }
    };
  }
}

export default taskConverter;
