import Input from 'components/Input';

export default function PlanForm({register}) {

  return (
    <div className="modal-form">
      <div className="columns is-multiline">
        <Input label="Start Date" type="datetime-local" register={register} name="start"
               helper="Mandatory" helperClass="help text-danger" validation={{required: true}}/>
        <Input label="End Date" type="datetime-local" register={register} name="end" helper="Mandatory"
               helperClass="help text-danger" validation={{required: true}}/>
        <Input label="Title" placeHolder="Ex: A white panner" name="title" helper="Mandatory" size={12}
               helperClass="help text-danger" register={register} validation={{required: true}}/>
        <Input label="Content" placeHolder="Details about the task..." name="content" helper="Mandatory"
               helperClass="help text-danger" type="textarea"
               options={{rows: 3, required: true}}
               register={register} validation={{required: true}} size="12"
        />
        <Input label="Notes" placeHolder="Additional information about the task..." name="notes"
               helper="Optional" type="textarea" options={{rows: 2}}
               register={register} size="12"
        />
      </div>
    </div>
  )
}
