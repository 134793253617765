
import Model from "models/Model.js";
import Joi from "joi";

export default class TemplatesCategory extends Model{

  data = {
    id: "",
    title: "",
    description: "",
    imageURL: "",
  }

  schema = Joi.object({
    id: Joi.string().allow(''),
    title: Joi.string().min(3).max(30),
    description: Joi.string().allow(''),
    imageURL: Joi.string().allow(''),
  })

  constructor(data) {
    super();
    this.data = {...this.data, ...data};
    return this;
  }
}
