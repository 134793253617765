import {useLayout} from "contexts/LayoutContext";
import CreateModal from "./CreateModal";

import 'views/custom.scss'

export default function Create() {

  const {modalIsOpen, toggleModal} = useLayout()

  function toggleIsOpen() {
    toggleModal("createTask")
  }

  return(
    modalIsOpen['createTask'] && <CreateModal toggleIsOpen={toggleIsOpen} />
  )
}
