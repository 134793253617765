import {doc, where} from 'firebase/firestore';
import {useDispatch, useSelector} from 'react-redux'
import {useFirestore} from 'reactfire';
import {fetchTemplates, selectTemplates} from "slices/taskSlice";
import TemplateRow from 'views/templates/TemplateRow';
import {useEffect} from "react";
import {useAuth} from "contexts/AuthContext";
import {useParams} from 'react-router-dom';
import "views/custom.scss"

export default function ListTemplates() {

  let { categoryID } = useParams()
  const {currentUser} = useAuth()
  const dispatch = useDispatch()
  const firestore = useFirestore()

  useEffect(() => {
    const filters = [];
    if(categoryID)
      filters.push(where('category_link', '==',
        doc(firestore, `users/${currentUser.uid}/templates_categories/${categoryID}`)))
    else
      filters.push(where('category_link', '==', ''))
    dispatch(fetchTemplates(currentUser.uid, filters))
  }, [])


  const templates = useSelector(selectTemplates)

  return(
    <div className="page-content-inner">
      <div className="columns tasks-table">
        <div className="column is-12">
          <div className="s-card demo-table">
            <table className="table is-hoverable is-fullwidth">
              <tbody>
              <tr>
                <th style={{width: '20%'}}>Title</th>
                <th style={{width: '35%'}}>Content</th>
                <th style={{width: '35%'}}>Notes</th>
                <th style={{width: '2%'}} className="is-end" colSpan={2}>
                  <div className="dark-inverted">
                    Actions
                  </div>
                </th>
              </tr>
              {
                templates?.map( (template, index) => (
                    <TemplateRow key={index} template={template} />
                  )
                )
              }
              </tbody>
            </table>
          </div>
        </div>
      </div>

    </div>
  )
}
