import MultiSelectPicker from "components/MultiSelectPicker";
import {useAuth} from "contexts/AuthContext";
import {useButtonListener} from 'hooks/utils';
import {useFirestore} from "reactfire";
import {useState} from "react";
import {useForm} from "react-hook-form";
import Task from "models/Task";
import {doc} from "firebase/firestore";
import {update} from "API.js";
import Input from "components/Input";

export default function Update({ task, setIsOpen}) {

  // Constants
  const firestore = useFirestore();
  const {currentUser} = useAuth()

  // TODO: tabs
  const [activeTab] = useState('basic-details')

  const { register, handleSubmit, control, reset, formState } = useForm({
    defaultValues: {
      title: task.title,
      content: task.content,
      notes: task.notes,
      folder_name: task.folder_name,
      design_sizes: task.design_sizes
    }
  })
  const { isDirty } = formState;

  const designSizes = currentUser.data['design_sizes']?.map(size => ({value: size, label: size})) || []

  // Methods
  async function onSubmit(data) {
    try {
      const taskData = new Task({...task, ...data})
      await taskData.validate()
      const taskRef = doc(firestore, "tasks", taskData.data.id)
      await update(taskRef, data)
      setIsOpen(false)
    } catch (err) {
      await swal('Warning', err.message, 'warning')
    }
  }


  async function handleCancel(e) {
    e?.preventDefault()
    if(isDirty && !confirm('Are you sure you want to cancel the edits you have made?')) {
      return;
    }
    await reset();
    setIsOpen(false)
  }

  useButtonListener(handleCancel, 27, [formState])

  return(
    <div id="demo-big-form-modal" className='modal h-modal is-big is-active'>
      <div className="modal-background h-modal-close"/>
      <div className="modal-content">
        <form className="modal-card" onSubmit={handleSubmit(onSubmit)}>
          <header className="modal-card-head">
            <h3>Edit Task</h3>
            <button className="h-modal-close ml-auto" aria-label="close" onClick={() => setIsOpen(false)}>
              <i className="bi bi-x"/>
            </button>
          </header>
          <div className="modal-card-body">
            <div className="inner-content">
              <div className="tabs-wrapper">
                {/* TODO: tabs are added later */}
                {/*<div className="tabs-inner">*/}
                {/*  <div className="tabs is-centered">*/}
                {/*    <ul>*/}
                {/*      <li data-tab="basic-details" className={`${activeTab === 'basic-details' && 'is-active'}`}>*/}
                {/*        <a href="#" onClick={() => { setActiveTab('basic-details') }}>*/}
                {/*          <i className="bi bi-pencil-square mr-2"/>*/}
                {/*          <span>Basic Details</span>*/}
                {/*        </a>*/}
                {/*      </li>*/}
                {/*      <li data-tab="files-&-records" className={`${activeTab === 'files-&-records' && 'is-active'}`}>*/}
                {/*        <a href="#" onClick={() => { setActiveTab('files-&-records') }}>*/}
                {/*          <i className="fas fa-paperclip"/>*/}
                {/*          <span>Files and Records</span>*/}
                {/*        </a>*/}
                {/*      </li>*/}
                {/*    </ul>*/}
                {/*  </div>*/}
                {/*</div>*/}

                <div id="basic-details" className={`tab-content ${activeTab === 'basic-details' && `is-active`}`}>
                  <div className="modal-form">
                    <div className="columns is-multiline">
                      {/*<input type="hidden" {...register('creation_date')} value={task.creation_date} />*/}
                      <Input label="Title" placeHolder="Ex: A white panner" name="title" helper="Optional"
                             register={register} />
                      <Input label="Content" placeHolder="Details about the task..." name="content" helper="Mandatory"
                             helperClass="help text-danger" type="textarea"
                             options={{rows: 3, required: true}}
                             register={register} validation={{required: true}} size="12"
                      />
                      <Input label="Notes" placeHolder="Additional information about the task..." name="notes"
                             helper="Optional" type="textarea" options={{rows: 3}}
                             register={register} size="12"
                      />
                      <Input label="Folder Name" placeHolder="Ex: Assets" name="folder_name" helper="Optional"
                             register={register} />
                      <div className="column is-6">
                        <div className="field">
                          <label>Design Sizes</label>
                          <div className="control">
                            <MultiSelectPicker
                              control={control} options={designSizes} name="design_sizes" menuPlacement={'top'}
                              args={{validation: {required: true}}}
                            />
                          </div>
                          <p className="help text-danger">Mandatory</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="files-&-records" className={`tab-content ${activeTab === 'files-&-records' && `is-active`}`}>

                </div>
              </div>
            </div>
          </div>
          <div className="modal-card-foot is-flex-wrap-wrap-reverse is-justify-content-space-around	">
            <button className="button h-button is-rounded h-modal-close" onClick={handleCancel}>
              Cancel
            </button>
            <button className="button h-button is-primary is-raised is-rounded mb-2" type="submit">Update</button>
          </div>
        </form>
      </div>
    </div>
  )
}
