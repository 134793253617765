
import Model from "models/Model.js";
import Joi from "joi";

export default class Task extends Model{
  static get STATUS() {
    return {
      PENDING: 'Pending',
      MISSING_INFORMATION: 'Missing Information',
      REJECTED: 'Rejected',
      APPROVED: 'Approved',
      IN_PROGRESS: 'In Progress',
      UPLOADED: 'Uploaded',
      DONE: 'Done',
    }
  }

  static get MainStatusArray() {
    const statuses = Object.values(this.STATUS)
    statuses.splice(2, 1)
    return statuses;
  }

  data = {
    client_id: "",
    title: "",
    content: "",
    notes: "",
    status: Task.STATUS.PENDING,
    admin_notes: "",
    creation_date: "",
    created_at: new Date(),
    folder_name: '',
    design_sizes: [],
    assignees_ids: [],
    category_link: null,
    is_draft: false,
    imageURL: '',
  }

  schema = Joi.object({
    client_id: Joi.string(),
    id: Joi.string(),
    title: Joi.string().allow(''),
    content: Joi.string(),
    notes: Joi.string().allow(''),
    creation_date: Joi.date(),
    created_at: Joi.date(),
    status: Joi.allow(
      Task.STATUS.PENDING, Task.STATUS.MISSING_INFORMATION, Task.STATUS.REJECTED, Task.STATUS.APPROVED,
      Task.STATUS.IN_PROGRESS, Task.STATUS.UPLOADED, Task.STATUS.DONE
    ).only(),
    admin_notes: Joi.allow(''),
    design_sizes: Joi.array().min(1).message("You should choose at least one design size"),
    folder_name: Joi.string().allow(''),
    assignees_ids: Joi.array().items(Joi.string()),
    category_link: Joi.object().allow(null),
    is_draft: Joi.boolean(),
    order: Joi.number().allow(null),
    imageURL: Joi.string().allow(''),
  })

  constructor(data) {
    super();
    this.data = {...this.data, ...data};
    return this;
  }
}
