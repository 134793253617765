import TemplatesCategory from 'models/TemplatesCategory';

const templatesCategoryConverter = {
  toFirestore: (data) => {
    const templatesCategory = new TemplatesCategory(data)
    templatesCategory.validate()
    return templatesCategory.data;
  },
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    return {
      ...data, ...{
        created_at: data.created_at?.toDate()
      }
    };
  }
}

export default templatesCategoryConverter;
