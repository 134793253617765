import {create} from 'API';
import {useAuth} from 'contexts/AuthContext';
import planConverter from 'converters/planConverter';
import {collection} from "firebase/firestore";
import {useButtonListener} from 'hooks/utils';
import moment from 'moment';
import {useState} from "react";
import {useForm} from "react-hook-form";
import {useFirestore} from "reactfire";
import swal from 'sweetalert';
import PlanForm from 'views/plans/PlanForm';

export default function CreatePlan({toggleIsOpen, defaultStartDate, defaultEndDate}) {

  // Hooks
  const firestore = useFirestore()
  const {currentUser} = useAuth()

  const {register, handleSubmit, reset, formState} = useForm({
    defaultValues: {
      client_id: currentUser.uid,
      start: moment(defaultStartDate).format(moment.HTML5_FMT.DATETIME_LOCAL),
      end: moment(defaultEndDate).format(moment.HTML5_FMT.DATETIME_LOCAL)
    }
  });
  const {isDirty} = formState;

  // States
  const [isLoading, setIsLoading] = useState(false)

  // Methods
  async function onSubmit(data) {
    try {
      setIsLoading(true)
      await create(collection(firestore, "plans").withConverter(planConverter), data)
      setIsLoading(false)
      reset();
      return toggleIsOpen(false)
    } catch (err) {
      setIsLoading(false)
      await swal('Warning', err.message, 'warning')
    }
  }

  function handleCancel(e) {
    e?.preventDefault()
    if (isDirty && !confirm('Are you sure you want to cancel the edits you have made?'))
      return;
    reset();
    toggleIsOpen()
  }

  useButtonListener(handleCancel, 27, [formState])

  return (
    <div className="modal h-modal is-big is-active">
      <div className="modal-background h-modal-close"/>
      <div className="modal-content">
        <form className="modal-card" onSubmit={handleSubmit(onSubmit)}>
          <header className="modal-card-head">
            <h3>Create Plan</h3>
            <a href="#" className="h-modal-close ml-auto" aria-label="close" onClick={() => toggleIsOpen()}>
              <i className="bi bi-x"/>
            </a>
          </header>
          <div className="modal-card-body">
            <div className="inner-content">
              <div className="tabs-wrapper">
                <div className={`tab-content is-active`}>
                  <PlanForm register={register}/>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-card-foot is-end">
            <button className={`button h-button h-modal-close ${isLoading && 'is-loading'}`} onClick={handleCancel}>
              Cancel
            </button>

            <button className={`button h-button is-primary ${isLoading && 'is-loading'}`} type="submit">
              <span>Create</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
