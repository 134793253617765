import moment from 'moment'
import {useCallback} from 'react';
import {Calendar as BaseCalendar, momentLocalizer} from 'react-big-calendar'

import './Calendar.scss'

const localizer = momentLocalizer(moment)

export default function Calendar({events = [], defaultDate = new Date(), onSelectSlot, onSelectEvent}) {

  const handleSelectSlot = useCallback(
    async ({start, end}) => {
      onSelectSlot(start, end)
    }, []
  )

  const handleSelectEvent = useCallback(
    (event) => onSelectEvent(event),
    []
  )

  return (
    <BaseCalendar
      localizer={localizer}
      events={events}
      startAccessor="start"
      endAccessor="end"
      onSelectEvent={handleSelectEvent}
      onSelectSlot={handleSelectSlot}
      selectable
      defaultDate={defaultDate}
    />
  )
}
