import {collection, getDocs, query} from 'firebase/firestore';
import {useEffect, useState} from "react";
import {useFirestore} from 'reactfire';

export default function getTemplatesCategoriesIcons() {

  const firestore = useFirestore()
  const [icons, setIcons] = useState([])

  useEffect(() => {
    const q = query(collection(firestore, `icons`))
    getDocs(q)
      .then(querySnapshot => {
        const iconsArr = [];
        querySnapshot.forEach((doc) => {
          iconsArr.push({...doc.data(), ...{id: doc.id}})
        })
        setIcons(iconsArr)
      })
  }, [])

  return icons;
}
