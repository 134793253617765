import Input from "components/Input";
import MultiSelectPicker from "components/MultiSelectPicker";
import {useAuth} from "contexts/AuthContext";
import taskConverter from 'converters/taskConverter';
import templateConverter from 'converters/templateConverter';
import {addDoc, collection, doc} from "firebase/firestore";
import useDropdown from 'hooks/useDropdown';
import {useButtonListener} from 'hooks/utils';
import moment from 'moment';
import {useState} from "react";
import {useForm} from "react-hook-form";
import {useFirestore} from "reactfire";
import swal from 'sweetalert';
import CreateTemplatesCategoryModal from 'views/templates/categories/CreateTemplatesCategoryModal';
import TemplatesCategoriesDropDown from 'views/templates/TemplatesCategoriesDropDown';

export default function CreateModal({toggleIsOpen, templateTask}) {

  // Hooks
  const {currentUser} = useAuth()
  const firestore = useFirestore()
  const [dropdownIsOpen, toggleDropdown] = useDropdown()
  const [templatesCategoryModalIsOpen, setTemplatesCategoryModalIsOpen] = useState(false)

  // Constants
  const designSizes = currentUser.data['design_sizes']?.map(size => ({value: size, label: size})) || []

  const { register, handleSubmit, control, reset, formState, getValues } = useForm({
    defaultValues: {
      client_id: currentUser.uid,
      creation_date: moment(templateTask?.creation_date).format("YYYY-MM"),
      title: templateTask?.title,
      design_sizes: templateTask?.design_sizes || [designSizes[0]?.value],
      content: templateTask?.content,
      notes: templateTask?.notes,
      folder_name: templateTask?.folder_name
    }
  });
  const { isDirty } = formState;

  // States
  const [activeTab] = useState('basic-details')
  const [persist, setPersist] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [willSaveTemplate, setWillSaveTemplate] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState(null)

  // Methods
  async function onSubmit(data) {
    try {
      setIsLoading(true)
      await addDoc(collection(firestore, "tasks").withConverter(taskConverter), data)
      setIsLoading(false)
      // Save template
      if(willSaveTemplate) {
        if(selectedCategory)
          data.category_link = doc(firestore, `users/${currentUser.uid}/templates_categories`, selectedCategory.id)
        await addDoc(collection(firestore, `users/${currentUser.uid}/templates`).withConverter(templateConverter), data)
      }
      await swal('Success', `Task created successfully`, 'success', {timer: 1000})
      await resetForm(data)
      // Keep the modal open if persist is true
      persist ? setPersist(false) : toggleIsOpen()
    } catch (err) {
      setIsLoading(false)
      await swal('Warning', err.message, 'warning')
    }
  }

  async function addTemplate() {
    if(willSaveTemplate) {
      setIsLoading(true)
      const data = getValues()
      if(selectedCategory)
        data.category_link = doc(firestore, `users/${currentUser.uid}/templates_categories`, selectedCategory.id)
      await addDoc(collection(firestore, `users/${currentUser.uid}/templates`).withConverter(templateConverter), data)
      await swal('Success', `Template created successfully`, 'success', {timer: 1000})
      setIsLoading(false)
      await resetForm(data)
      toggleIsOpen()
    } else {
      alert('Activate template toggle first')
    }
  }

  function handleCancel(e) {
    e?.preventDefault()
    if(isDirty && !confirm('Are you sure you want to cancel the edits you have made?')) {
      return;
    }
    reset();
    toggleIsOpen()
  }

  async function resetForm(data) {
    await reset({
      client_id: currentUser.uid,
      creation_date: moment().format("YYYY-MM"),
      title: '',
      design_sizes: data?.design_sizes,
      content: '',
      notes: '',
      folder_name: ''
    })
  }

  useButtonListener(handleCancel, 27, [formState])

  return(
    <div id="demo-big-form-modal" className='modal h-modal is-big is-active'>
      <div className="modal-background h-modal-close"/>
      <div className="modal-content">
        <form className="modal-card" onSubmit={handleSubmit(onSubmit)}>
          <header className="modal-card-head">
            <h3>Create Task</h3>
            <a href="#" className="h-modal-close ml-auto" aria-label="close" onClick={() => toggleIsOpen()}>
              <i className="bi bi-x"/>
            </a>
          </header>
          <div className="modal-card-body">
            <div className="inner-content">
              <div className="tabs-wrapper">
                {/* TODO: TABS; this will be added later */}
                {/*<div className="tabs-inner">*/}
                {/*  <div className="tabs is-centered">*/}
                {/*    <ul>*/}
                {/*      <li data-tab="basic-details" className={`${activeTab === 'basic-details' && 'is-active'}`}>*/}
                {/*        <a href="#" onClick={() => { setActiveTab('basic-details') }}>*/}
                {/*          <i className="bi bi-pencil-square mr-2"/>*/}
                {/*          <span>Basic Details</span>*/}
                {/*        </a>*/}
                {/*      </li>*/}
                {/*      <li data-tab="files-&-records" className={`${activeTab === 'files-&-records' && 'is-active'}`}>*/}
                {/*        <a href="#" onClick={() => { setActiveTab('files-&-records') }}>*/}
                {/*          <i className="fas fa-paperclip"/>*/}
                {/*          <span>Files and Records</span>*/}
                {/*        </a>*/}
                {/*      </li>*/}
                {/*    </ul>*/}
                {/*  </div>*/}
                {/*</div>*/}

                <div className={`tab-content ${activeTab === 'basic-details' && `is-active`}`}>
                  <div className="modal-form">
                    <div className="columns is-multiline">
                      <Input label="Creation Date" type="month" register={register} name="creation_date" helper="Mandatory"
                             helperClass="help text-danger" validation={{required: true}} size={5}
                             options={{min: moment().format("YYYY-MM"),
                               max: moment().add(1, 'y').format("YYYY-MM")}} />
                      <div className="column is-6 m-auto columns">
                        <div className="field column">
                          <label>Template</label>
                          <div className="control">
                            <label className="form-switch is-primary">
                              <input type="checkbox" className="is-switch" defaultChecked={willSaveTemplate}
                                     onChange={() => {
                                       setWillSaveTemplate(!willSaveTemplate)
                                       setSelectedCategory(null)
                                     }}
                              />
                              <i/>
                            </label>
                          </div>
                        </div>
                        {willSaveTemplate &&
                          <div className="field column my-auto">
                            <div className="control">
                              <TemplatesCategoriesDropDown
                                selectedCategory={selectedCategory}
                                setSelectedCategory={setSelectedCategory}
                                setNewCategoryIsOpen={setTemplatesCategoryModalIsOpen}
                              />
                            </div>
                          </div>}
                      </div>
                      <Input label="Title" placeHolder="Ex: A white panner" name="title" helper="Optional"
                             register={register} size={12} />
                      <Input label="Content" placeHolder="Details about the task..." name="content" helper="Mandatory"
                             helperClass="help text-danger" type="textarea"
                             options={{rows: 3, required: true}}
                             register={register} validation={{required: true}} size="12"
                      />
                      <Input label="Notes" placeHolder="Additional information about the task..." name="notes"
                             helper="Optional" type="textarea" options={{rows: 2}}
                             register={register} size="12"
                      />
                      <div className="column is-6">
                        <div className="field">
                          <label>Design Sizes</label>
                          <div className="control">
                            <MultiSelectPicker
                              control={control} options={designSizes} name="design_sizes"
                              args={{validation: {required: true}}} menuPlacement={'top'}
                            />
                          </div>
                          <p className="help text-danger">Mandatory</p>
                        </div>
                      </div>
                      <Input label="Folder Name" placeHolder="Ex: Assets" name="folder_name" helper="Optional"
                             register={register} />
                    </div>
                  </div>
                </div>

                <div id="files-&-records" className={`tab-content ${activeTab === 'files-&-records' && `is-active`}`}>

                </div>
              </div>
            </div>
          </div>
          <div className="modal-card-foot is-flex-wrap-wrap-reverse is-justify-content-space-around">
            <a href="#" className={`button h-button is-info is-raised mr-auto ${isLoading && 'is-loading'}`}
               onClick={() => addTemplate()}>
              Create Template Only
            </a>
            <button className={`button h-button h-modal-close ${isLoading && 'is-loading'}`} onClick={handleCancel}>
              Cancel
            </button>
            <div className="field has-addons">
              <div className="control">
                <button className={`button h-button is-primary ${isLoading && 'is-loading'}`} type="submit">
                  <span>Create</span>
                </button>
              </div>
              <div className="control">
                <div className={`dropdown dropdown-trigger is-up is-right ${dropdownIsOpen && 'is-active'}`}>
                  <div className="is-trigger">
                    <a href="#" className={`button is-primary ${isLoading && 'is-loading'}`} onClick={toggleDropdown}>
                      <span className="icon is-small">
                          <i className="fas fa-angle-up"/>
                      </span>
                    </a>
                  </div>
                  <div className="dropdown-menu" role="menu">
                    <div className="dropdown-content">
                      <button className="button is-white dropdown-item font-size-base has-text-black" type="submit"
                              onClick={() => setPersist(true)}>
                        Create & Add More
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        {templatesCategoryModalIsOpen && <CreateTemplatesCategoryModal setIsOpen={setTemplatesCategoryModalIsOpen} />}
      </div>
    </div>
  )
}
