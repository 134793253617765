import useDropdown from "hooks/useDropdown";
import PropTypes from 'prop-types';

function DropdownMenu({selectedOption = null, title, options = [], className = '', color = '', setActive, disabled}) {

  const [isOpen, toggleDropdown] = useDropdown()

  const changeOption = (option) => {
    setActive(option)
  }

  return(
    <div className={`dropdown dropdown-trigger ${isOpen && 'is-active'} ${className}`}>
      <div className="is-trigger">
        <button className={`button h-button is-raised is-outlined is-${color}`} aria-haspopup="true" onClick={() => toggleDropdown()} disabled={disabled}
                aria-controls="dropdown-menu">
          <span>{selectedOption || title}</span>
          <span className="icon is-small">
            <i className="fas fa-angle-down" aria-hidden="true"/>
          </span>
        </button>
      </div>
      <div className="dropdown-menu" id="dropdown-menu" role="menu">
        <div className="dropdown-content">
          {options.map( (option, index) =>
            <a href="#" className="dropdown-item font-size-base" key={index} onClick={() => changeOption(option)}>
              {option}
            </a>
          )}
        </div>
      </div>
    </div>
  )
}

DropdownMenu.propTypes = {
  title: PropTypes.string,
  options: PropTypes.array,
  className: PropTypes.string,
  color: PropTypes.string,
  setActive: PropTypes.func
}

export default DropdownMenu
