// Client Views
import constants from "constants.js"

// Auth Views
import Login from 'views/auth/Login';
import Calendar from 'views/Calendar';
import EditProfile from 'views/EditProfile';
import Insights from 'views/Insights';
import PlansIndex from 'views/plans/PlansIndex';
import SubscriptionsIndex from 'views/subscriptions/SubscriptionsIndex';
import TasksIndex from 'views/tasks/Index';
import CategoriesIndex from 'views/templates/categories/Index';
import ListTemplates from 'views/templates/ListTemplates';


const routes = [
  // ========================================= Client routes =========================================
  {
    // TODO: Remove this later, after implementing dashboard
    path: '/',
    name: 'Insights',
    icon: 'activity',
    component: Insights,
    layout: constants.CLIENT,
  },
  {
    path: '/calendar',
    name: 'Calendar',
    icon: 'calendar',
    component: Calendar,
    layout: constants.CLIENT
  },
  {
    path: '/tasks',
    name: 'Tasks',
    icon: 'list',
    component: TasksIndex,
    layout: constants.CLIENT
  },
  {
    path: '/plans',
    name: 'Plans',
    icon: 'book-open',
    component: PlansIndex,
    layout: constants.CLIENT
  },
  {
    path: '/templatesCategories',
    name: 'Templates',
    icon: 'file',
    component: CategoriesIndex,
    layout: constants.CLIENT
  },
  {
    path: '/templates/:categoryID',
    component: ListTemplates,
    layout: constants.CLIENT,
    hide: true
  },
  {
    path: '/templates',
    component: ListTemplates,
    layout: constants.CLIENT,
    hide: true
  },
  {
    path: '/subscriptions',
    component: SubscriptionsIndex,
    name: 'Subscription Management',
    icon: 'user',
    layout: constants.CLIENT
  },
  {
    path: '/profile/edit',
    name: 'Edit Profile',
    icon: 'user',
    component: EditProfile,
    layout: constants.CLIENT,
    hide: true
  },
  // ========================================= Auth routes =========================================
  {
    path: '/login',
    name: 'Login',
    icon: 'login',
    component: Login,
    layout: constants.AUTH,
  },
];
export default routes;
