import Input from 'components/Input';
import {useAuth} from 'contexts/AuthContext';
import templatesCategoryConverter from 'converters/templatesCategoryConverter';
import {addDoc, collection} from 'firebase/firestore';
import getTemplatesCategoriesIcons from 'hooks/getTemplatesCategoriesIcons';
import {useButtonListener} from 'hooks/utils';
import {useState} from 'react';
import {useForm} from 'react-hook-form';
import {useFirestore} from 'reactfire';
import swal from 'sweetalert';

export default function CreateTemplatesCategoryModal({setIsOpen}) {

  // states
  const [isLoading, setIsLoading] = useState(false)
  const [descriptionIsOpen, setDescriptionIsOpen] = useState(false)
  const [imagePickerIsOpen, setImagePickerIsOpen] = useState(false)
  const [chosenIcon, setChosenIcon] = useState('')
  const icons = getTemplatesCategoriesIcons()


  // Hooks
  const firestore = useFirestore()
  const {currentUser} = useAuth()
  const { register, handleSubmit, reset, formState, setValue } = useForm({
    defaultValues: {
      title: '',
      description: '',
      imageURL: ''
    }
  });
  const { isDirty } = formState;

  // Methods
  async function onSubmit(data) {
    try {
      setIsLoading(true)
      await addDoc(collection(firestore, `users/${currentUser.uid}/templates_categories`)
        .withConverter(templatesCategoryConverter), data)
      setIsLoading(false)
      await swal('Success', `Category created successfully`, 'success', {timer: 1000})
      await reset()
      toggleModal()
    } catch (err) {
      setIsLoading(false)
      await swal('Warning', err.message, 'warning')
    }
  }

  function handleCancel(e) {
    e?.preventDefault()
    if(isDirty && !confirm('Are you sure you want to cancel the edits you have made?')) {
      return;
    }
    reset();
    toggleModal()
  }

  const toggleModal = (open = false) => {
    setDescriptionIsOpen(open)
    setImagePickerIsOpen(open)
    setIsOpen(open)
  }

  const assignIcon = (imageURL) => {
    setValue('imageURL', imageURL)
    setChosenIcon(imageURL)
  }

  useButtonListener(handleCancel, 27, [formState])

  return(
    <div className={`modal h-modal is-big is-active`}>
      <div className="modal-background h-modal-close"/>
      <div className="modal-content">
        <form className="modal-card" onSubmit={handleSubmit(onSubmit)}>
          <header className="modal-card-head">
            <h3>Create Templates Category</h3>
            <a href="#" className="h-modal-close ml-auto" aria-label="close" onClick={handleCancel}>
              <i className="bi bi-x"/>
            </a>
          </header>
          <div className="modal-card-body">
            <div className="inner-content">
              <div className="modal-form">
                <div className="columns is-multiline">
                  <Input label="Name" placeHolder="Enter category name.." name="title" helper="Mandatory"
                         helperClass="help text-danger" validation={{required: true}}
                         register={register} size={12} />
                  {descriptionIsOpen &&
                    <Input label="Description" placeHolder="Write more about the category.." type="textarea" name="description"
                           helper="Optional"
                           register={register} size={12}
                    />}
                  {imagePickerIsOpen && <div className={`column is-12`}>
                    <div className="field">
                      <label htmlFor={`icon-input`}>Icon</label>
                      <div className="control is-flex is-flex-wrap-wrap is-justify-content-space-evenly"
                           style={{gap: '25px'}}>
                        {icons.map((icon, index) => (
                          <div key={index} onClick={() => assignIcon(icon.imageURL)}
                               className={`h-icon is-large is-rounded is-clickable ${chosenIcon === icon.imageURL && 'is-bordered'}`}>
                            <img src={icon.imageURL} alt=""/>
                          </div>
                        ))}
                      </div>
                      <p className="help">Optional</p>
                    </div>
                  </div>}

                  <div className={`column is-6`}>
                    <div className="field">
                        <span className={`tag is-clickable mr-2 ${descriptionIsOpen && 'is-success'}`}
                              onClick={() => {
                                setValue('description', '')
                                setDescriptionIsOpen(!descriptionIsOpen)
                              }}>
                          <i className="fas fa-info-circle" style={{fontSize: 'unset'}}/> Add Description
                        </span>
                      <span className={`tag is-clickable ${imagePickerIsOpen && 'is-success'}`}
                            onClick={() => {
                              assignIcon('')
                              setImagePickerIsOpen(!imagePickerIsOpen)
                            }}>
                          <i className="fas fa-info-circle" style={{fontSize: 'unset'}}/> Add Icon
                        </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-card-foot is-end">
            <a className={`button h-button h-modal-close ${isLoading && 'is-loading'}`} onClick={handleCancel}>Cancel</a>
            <button className={`button h-button is-primary is-raised ${isLoading && 'is-loading'}`} type="submit">Confirm</button>
          </div>
        </form>
      </div>
    </div>
  )
}
