export default function CircleChart({percentage}) {
  let color;
  if(percentage < 10) {
    color = '#e62965'
  }
  else if (percentage < 40) {
    color = '#faae42'
  } else if (percentage < 70) {
    color = '#039be5'
  } else {
    color = '#06d6a0'
  }

  return(
    <svg className="circle-chart"
         viewBox="0 0 45 45" width="60" height="60" xmlns="http://www.w3.org/2000/svg">
      <circle className="circle-chart__background" strokeWidth="5" fill="none" cx="50%" cy="50%" r="15.91549431"/>
      <circle className="circle-chart__circle" strokeWidth="5" strokeDasharray={`${percentage},100`} strokeLinecap="round"
              fill="none" cx="50%" cy="50%" r="15.91549431" style={{stroke: color}} />
    </svg>
  )
}
