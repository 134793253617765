import {getSubscriptions} from 'hooks/stripe';
import ProductsIndex from 'views/products/ProductsIndex';

export default function SubscriptionsIndex() {

  const subscriptions = getSubscriptions()

  return(
    <div className="page-content-inner">
      <h1 className="title is-4 is-narrow">Your Subscription</h1>
      <br />
      {subscriptions.map((subscription, index) => (
        <div className="s-card content p-1" key={index}>
          <blockquote className={`${subscription.status === 'active' ? 'is-success': 'is-danger'}`}>
            <h1 className="title is-4">{subscription.product.name.split('-')[0]} <span className="has-text-info">{subscription.product.name.split('-')[1]}</span></h1>
            <p className="subtitle is-6">Expires on
              <span className="has-text-primary"> {subscription.current_period_end.toDateString()}</span>
            </p>
          </blockquote>
        </div>
      ))}
      <ProductsIndex />
    </div>
  )
}
