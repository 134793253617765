import {collection, query, onSnapshot} from "firebase/firestore";
import { createSlice } from '@reduxjs/toolkit'
import {firestore} from "firebase.js";
import store from "store.js";


// =========================== Slice ===========================
export const templatesCategorySlice = createSlice({
  name: 'templatesCategory',
  initialState: {
    categories: [],
  },
  reducers: {
    set: (state, action) => {
      state.categories = action.payload
    }
  },
})

// ============================= Actions =============================
export const { set } = templatesCategorySlice.actions;

// ============================= Thunks =============================
export const fetchCategories = (userID) => async dispatch => {
  const categories = store.getState().templatesCategory.categories
  if(categories.length !== 0) return;
  const q = query(collection(firestore, `users/${userID}/templates_categories`))
  onSnapshot(q, (querySnapshot) => {
    const categories = [];
    querySnapshot.forEach((doc) => {
      categories.push({...doc.data(), ...{id: doc.id}})
    });
    dispatch(set(categories))
  })
};

// ============================ Selectors ============================
export const selectCategories = state => state.templatesCategory.categories;


// =========================== Default Export =========================
export default templatesCategorySlice.reducer
