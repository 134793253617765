import {useAuth} from 'contexts/AuthContext';
import useDropdown from 'hooks/useDropdown';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {fetchCategories, selectCategories} from 'slices/templatesCategorySlice';
import truncate from 'truncate';

export default function TemplatesCategoriesDropDown({selectedCategory, setSelectedCategory, setNewCategoryIsOpen = null}) {

  // Hooks
  const [dropdownIsOpen, toggleDropdown] = useDropdown()
  const dispatch = useDispatch()
  const {currentUser} = useAuth()

  // Effects
  useEffect(() => {
    dispatch(fetchCategories(currentUser.uid))
  }, [])

  // Selectors
  let categories = useSelector(selectCategories)

  return(
    <div className={`dropdown dropdown-trigger is-spaced is-right w-100 ${dropdownIsOpen && 'is-active'}`}>
      <div className="is-trigger w-100">
        <a href="#" className={`button h-button m-auto is-flex is-justify-content-space-around w-100`} onClick={toggleDropdown}
           aria-haspopup="true" aria-controls="dropdown-menu">
          <span className="icon is-small">
            {selectedCategory && <img src={selectedCategory?.imageURL} alt="..."/>}
          </span>
          <span>{truncate(selectedCategory?.title || 'Uncategorized', 30)}</span>
          <span className="icon is-small">
            <i className="fas fa-angle-down" aria-hidden="true"/>
          </span>
        </a>
      </div>
      <div className="dropdown-menu" role="menu">
        <div className="dropdown-content">
          <a href="#" className="dropdown-item is-media" onClick={() => setSelectedCategory(null)}>
            <div className="meta">
              <span>Uncategorized</span>
            </div>
          </a>
          {categories.map((category, index) => (
            <a href="#" className="dropdown-item is-media" key={index} onClick={() => setSelectedCategory(category)}>
              {category.imageURL && <div className="icon">
                <img src={category.imageURL} alt="..."/>
              </div>}
              <div className="meta">
                <span>{truncate(category.title, 30)}</span>
              </div>
            </a>
          ))}
          {setNewCategoryIsOpen &&
            <a href="#" className="dropdown-item is-media" onClick={() => setNewCategoryIsOpen(true)}>
              <div className="icon">
                <i className="lnil lnil-plus"/>
              </div>
              <div className="meta">
                <span className="has-text-primary">Create New Category</span>
              </div>
            </a>
          }
        </div>
      </div>
    </div>
  )
}
