import {update} from 'API';
import Input from 'components/Input';
import {useAuth} from 'contexts/AuthContext';
import {doc} from 'firebase/firestore';
import getTemplatesCategoriesIcons from 'hooks/getTemplatesCategoriesIcons';
import {useButtonListener} from 'hooks/utils';
import TemplatesCategory from 'models/TemplatesCategory';
import {useState} from 'react';
import {useForm} from 'react-hook-form';
import {useFirestore} from 'reactfire';
import swal from 'sweetalert';

export default function Update({category, setIsOpen}) {

  // states
  const [isLoading, setIsLoading] = useState(false)
  const [chosenIcon, setChosenIcon] = useState(category.imageURL)
  const icons = getTemplatesCategoriesIcons()

  // Hooks
  const firestore = useFirestore()
  const {currentUser} = useAuth()
  const { register, handleSubmit, reset, formState, setValue } = useForm({
    defaultValues: category
  });
  const { isDirty } = formState;

  // Methods
  async function onSubmit(data) {
    try {
      setIsLoading(true)
      const categoryData = new TemplatesCategory({...category, ...data})
      await categoryData.validate()
      const categoryRef = doc(firestore, `users/${currentUser.uid}/templates_categories`, category.id)
      await update(categoryRef, data)
      setIsLoading(false)
      await reset()
      setIsOpen(false)
    } catch (err) {
      setIsLoading(false)
      await swal('Warning', err.message, 'warning')
    }
  }

  function handleCancel(e) {
    e?.preventDefault()
    if(isDirty && !confirm('Are you sure you want to cancel the edits you have made?')) {
      return;
    }
    reset();
    setIsOpen(false)
  }

  const assignIcon = (imageURL) => {
    setValue('imageURL', imageURL)
    setChosenIcon(imageURL)
  }

  useButtonListener(handleCancel, 27, [formState])

  return(
    <>
      <div className={`modal h-modal is-big is-active`}>
        <div className="modal-background h-modal-close"/>
        <div className="modal-content">
          <form className="modal-card" onSubmit={handleSubmit(onSubmit)}>
            <header className="modal-card-head">
              <h3>Update Category</h3>
              <a href="#" className="h-modal-close ml-auto" aria-label="close" onClick={handleCancel}>
                <i className="bi bi-x"/>
              </a>
            </header>
            <div className="modal-card-body">
              <div className="inner-content">
                <div className="modal-form">
                  <div className="columns is-multiline">
                    <Input label="Name" placeHolder="Enter category name.." name="title" helper="Mandatory"
                           helperClass="help text-danger" validation={{required: true}}
                           register={register} size={12} />
                    <Input label="Description" placeHolder="Write more about the category.." type="textarea" name="description"
                           helper="Optional"
                           register={register} size={12}
                    />
                    <div className={`column is-12`}>
                      <div className="field">
                        <label htmlFor={`icon-input`}>Icon</label>
                        <div className="control is-flex is-flex-wrap-wrap is-justify-content-space-around"
                             style={{gap: '25px'}}>
                          {icons.map((icon, index) => (
                            <div key={index} onClick={() => assignIcon(icon.imageURL)}
                                 className={`h-icon is-large is-rounded is-clickable ${chosenIcon === icon.imageURL && 'is-bordered'}`}>
                              <img src={icon.imageURL} alt=""/>
                            </div>
                          ))}
                        </div>
                        <p className="help">Optional</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-card-foot is-end">
              <a className={`button h-button h-modal-close ${isLoading && 'is-loading'}`} onClick={handleCancel}>Cancel</a>
              <button className={`button h-button is-primary is-raised ${isLoading && 'is-loading'}`} type="submit">Confirm</button>
            </div>
          </form>
        </div>
      </div>
    </>

  )
}
