import {useAuth} from 'contexts/AuthContext';
import {useState} from "react";
import {useFirestore} from 'reactfire';
import truncate from "truncate";
import ShowTemplate from "views/templates/ShowTemplate"
import CreateModal from "views/tasks/CreateModal";
import useDropdown from "hooks/useDropdown"
import FeatherIcon from "feather-icons-react";
import {doc, deleteDoc} from "firebase/firestore";
import UpdateTemplate from 'views/templates/UpdateTemplate';

export default function TemplateRow({template}) {
  const firestore = useFirestore()

  const [dropdownIsOpen, toggleDropdown] = useDropdown()
  const [showIsOpen, setShowIsOpen] = useState(false)
  const [updateIsOpen, setUpdateIsOpen] = useState(false)
  const [createIsOpen, setCreateIsOpen] = useState(false)
  const {currentUser} = useAuth()

  const handleRemove = async (e) => {
    if(!window.confirm("Are you sure you want to delete template permanently?")) return;
    e.preventDefault()
    const templateRef = doc(firestore, `users/${currentUser.uid}/templates`, template.id)
    await deleteDoc(templateRef)
  }

  return(
    <tr>
      <td dir="auto"><a href={'#'} onClick={() => setShowIsOpen(true)}>{truncate(template.title, 100)}</a></td>
      <td dir="auto"><a href={'#'} onClick={() => setShowIsOpen(true)}>{truncate(template.content, 100)}</a></td>
      <td dir="auto"><a href={'#'} onClick={() => setShowIsOpen(true)}>{truncate(template.notes, 100)}</a></td>
      <td>
        { showIsOpen && <ShowTemplate template={template} setIsOpen={setShowIsOpen}
                                      setCreateIsOpen={setCreateIsOpen} />}
        { updateIsOpen && <UpdateTemplate template={template} setIsOpen={setUpdateIsOpen} /> }
        { createIsOpen && <CreateModal templateTask={template} toggleIsOpen={() => setCreateIsOpen(false)}/>}
      </td>
      <td className="is-end">
        <div>
          <div onClick={toggleDropdown}
               className={`dropdown is-spaced is-dots is-right dropdown-trigger is-pushed-mobile ${dropdownIsOpen && 'is-active'}`}>
            <div className="is-trigger" aria-haspopup="true">
              <FeatherIcon icon={'more-vertical'} />
            </div>
            <div className="dropdown-menu" role="menu">
              <div className="dropdown-content">
                <a href={"#"} className="dropdown-item is-media" onClick={() => setShowIsOpen(true)}>
                  <div className="icon">
                    <i className="lnil lnil-eye"/>
                  </div>
                  <div className="meta">
                    <span>View</span>
                    <span>View task details</span>
                  </div>
                </a>
                <a href={"#"} className="dropdown-item is-media" onClick={() => setCreateIsOpen(true)}>
                  <div className="icon">
                    <i className="lnil lnil-plus"/>
                  </div>
                  <div className="meta">
                    <span>Create</span>
                    <span>Create task from this template</span>
                  </div>
                </a>
                <a href={"#"} className="dropdown-item is-media" onClick={() => setUpdateIsOpen(true)}>
                  <div className="icon">
                    <i className="lnil lnil-pencil-alt"/>
                  </div>
                  <div className="meta">
                    <span>Edit</span>
                    <span>Update template details</span>
                  </div>
                </a>
                <a href={"#"} className="dropdown-item is-media" onClick={handleRemove}>
                  <div className="icon">
                    <i className="lnil lnil-trash-can-alt-1"/>
                  </div>
                  <div className="meta">
                    <span>Delete</span>
                    <span>Delete template permanently</span>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </td>

    </tr>
  )
}
