import {update} from "API.js";
import Input from "components/Input";
import MultiSelectPicker from "components/MultiSelectPicker";
import {useAuth} from "contexts/AuthContext";
import {doc, getDoc} from "firebase/firestore";
import {useButtonListener} from 'hooks/utils';
import {useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import {useFirestore} from "reactfire";
import CreateTemplatesCategoryModal from 'views/templates/categories/CreateTemplatesCategoryModal';
import TemplatesCategoriesDropDown from 'views/templates/TemplatesCategoriesDropDown';

export default function UpdateTemplate({ template, setIsOpen}) {

  // Hooks
  const firestore = useFirestore();
  const {currentUser} = useAuth()
  const [category, setCategory] = useState(null)
  const [modalIsOpen, setModalIsOpen] = useState(false)


  const { register, handleSubmit, control, reset, formState } = useForm({
    defaultValues: {
      title: template.title,
      content: template.content,
      notes: template.notes,
      folder_name: template.folder_name,
      design_sizes: template.design_sizes
    }
  })
  const { isDirty } = formState;

  const designSizes = currentUser.data['design_sizes']?.map(size => ({value: size, label: size})) || []

  // Methods
  async function onSubmit(data) {
    try {
      if(category)
        data.category_link = doc(firestore, `users/${currentUser.uid}/templates_categories`, category.id)
      else
        data.category_link = ''
      const templateRef = doc(firestore, `users/${currentUser.uid}/templates`, template.id)
      await update(templateRef, data)
      setIsOpen(false)
    } catch (err) {
      await swal('Warning', err.message, 'warning')
    }
  }

  useEffect(() => {
    if(template.category_link) {
      getDoc(template.category_link)
        .then((docSnap)=> setCategory(docSnap.data()))
    }
  }, [])

  async function handleCancel(e) {
    e?.preventDefault()
    if(isDirty && !confirm('Are you sure you want to cancel the edits you have made?')) {
      return;
    }
    await reset();
    setIsOpen(false)
  }

  useButtonListener(handleCancel, 27, [formState])

  return(
    <div className='modal h-modal is-big is-active'>
      <div className="modal-background h-modal-close"/>
      <div className="modal-content">
        <form className="modal-card" onSubmit={handleSubmit(onSubmit)}>
          <header className="modal-card-head">
            <h3>Edit Template</h3>
            <button className="h-modal-close ml-auto" aria-label="close" onClick={() => setIsOpen(false)}>
              <i className="bi bi-x"/>
            </button>
          </header>
          <div className="modal-card-body">
            <div className="inner-content">
              <div className="tabs-wrapper">
                <div className={`tab-content is-active`}>
                  <div className="modal-form">
                    <div className="columns is-multiline">
                      <Input label="Title" placeHolder="Ex: A white panner" name="title" helper="Optional"
                             register={register} />
                      <div className="column is-6">
                        <div className="field">
                          <label>Category</label>
                          <div className="field">
                            <TemplatesCategoriesDropDown
                              selectedCategory={category}
                              setSelectedCategory={setCategory}
                              setNewCategoryIsOpen={setModalIsOpen}
                            />
                          </div>
                        </div>
                      </div>
                      <Input label="Content" placeHolder="Details about the task..." name="content" helper="Mandatory"
                             helperClass="help text-danger" type="textarea"
                             options={{rows: 3, required: true}}
                             register={register} validation={{required: true}} size="12"
                      />
                      <Input label="Notes" placeHolder="Additional information about the task..." name="notes"
                             helper="Optional" type="textarea" options={{rows: 3}}
                             register={register} size="12"
                      />
                      <Input label="Folder Name" placeHolder="Ex: Assets" name="folder_name" helper="Optional"
                             register={register} />
                      <div className="column is-6">
                        <div className="field">
                          <label>Design Sizes</label>
                          <div className="control">
                            <MultiSelectPicker
                              control={control} options={designSizes} name="design_sizes" menuPlacement={'top'}
                              args={{validation: {required: true}}}
                            />
                          </div>
                          <p className="help text-danger">Mandatory</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-card-foot is-end">
            <button className="button h-button is-raised h-modal-close" onClick={handleCancel}>
              Cancel
            </button>
            <button className="button h-button is-primary is-raised mb-2" type="submit">Update</button>
          </div>
        </form>
        {modalIsOpen && <CreateTemplatesCategoryModal setIsOpen={setModalIsOpen} />}
      </div>
    </div>
  )
}
