const subscriptionConverter = {
  toFirestore: (data) => {
    return data;
  },
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    return {
      ...data,
      ...{
        current_period_end: data.current_period_end?.toDate()
      }
    };
  }
}

export default subscriptionConverter;
