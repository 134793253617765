const templateConverter = {
  toFirestore: (data) => {
    delete data.client_id;
    delete data.creation_date;
    delete data.created_at;
    return {
      ...{
        category_link: '',
        ...data
      }
    };
  },
  fromFirestore: (snapshot, options) => {
    return snapshot.data(options);
  }
}

export default templateConverter;
