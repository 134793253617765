import {useAuth} from 'contexts/AuthContext';
import {getPrices, startCheckoutSession} from 'hooks/stripe';
import {useState} from 'react';

export default function PricesModal({product, setPricesModalIsOpen}) {

  // Hooks
  const [isLoading, setIsLoading] = useState(false)
  const prices = getPrices(product.id);

  return(
    <>
      <div className="modal h-modal is-active">
        <div className="modal-background h-modal-close">
          <a href="#" onClick={() => setPricesModalIsOpen(false)}
             className={`button h-button is-danger is-light p-absolute top-20 right-20 ${isLoading && 'is-loading'}`}>
            Close
          </a>
        </div>
        <div className="modal-content columns" style={{maxWidth: 'unset'}}>
          {prices.map((price, index) => (
            <PriceCard price={price} product={product} isLoading={isLoading} setIsLoading={setIsLoading}
                       key={index} />
          ))}
        </div>
      </div>
    </>
  )
}

function PriceCard({price, product, isLoading, setIsLoading}) {

  // Hooks
  const { currentUser } = useAuth()

  // Methods
  const handleClick = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    await startCheckoutSession(currentUser.uid, price)
    setIsLoading(false)
  }

  console.log(price)
  // noinspection JSUnresolvedVariable
  return(
    <div className="modal-card column is-6">
      <header className="modal-card-head" style={{borderBottom: 'unset'}}>
        <h3>{price.type === 'recurring' ?
          `Every ${price.interval_count} ${price.interval}${price.interval_count > 0 && 's'}`
          : 'One Time Payment'}
        </h3>
      </header>
      <div className="modal-card-body">
        <div className="inner-content">
          <div className="section-placeholder">
            <div className="placeholder-content">
              <h3 className="dark-inverted">
                {price.unit_amount / 100}<sup> {price.currency}</sup>/ {price.interval_count} {price.interval}{price.interval_count > 0 && 's'}
              </h3>
              <p>{price.description}</p>
              <table className="table is-hoverable is-fullwidth">
                <tbody>
                {price.trial_period_days &&
                  <tr>
                    <td>{price.trial_period_days} Days</td>
                    <td className="is-end" dir="auto">Free Trial</td>
                  </tr>}
                {Object.keys(product.metadata).map((key, index) => key !== 'firebaseRole' && (
                  <tr key={index}>
                    <td>{product.metadata[key]}</td>
                    <td className="is-end" dir="auto">{key}</td>
                  </tr>
                ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-card-foot is-end">
        <a className={`button h-button is-primary is-raised ${isLoading && 'is-loading'}`} onClick={handleClick}>
          Proceed with payment
        </a>
      </div>
    </div>
  )
}
