import useDropdown from 'hooks/useDropdown';
import useQuery from 'hooks/useQuery';
import moment from 'moment';
import React, {useEffect, useMemo} from "react";
import {useLocation, Switch, Redirect, Route, Link} from "react-router-dom";
import routes from "routes.js";
import Navbar from 'components/NavBar';
import {useLayout} from "contexts/LayoutContext";
import {selectNotifications, clearNotifications} from 'slices/notificationSlice';
import swal from 'sweetalert';
import CreateTask from "views/tasks/Create";
import {fetchMonthStats, fetchTasksMetaData} from "slices/taskSlice";
import {useAuth} from "contexts/AuthContext";
import {useDispatch, useSelector} from "react-redux";

// ============================ Assets ============================
import 'lightgallery/scss/lightgallery.scss';
import 'lightgallery/scss/lg-zoom.scss';


const MainLayout = () => {
  const mainContent = React.useRef(null)
  const location = useLocation()
  const {currentUser, subscriptionIsActivated, logout} = useAuth()
  const dispatch = useDispatch()
  const {switchTheme, isLightTheme, activeSidebarClass, sidebarIsOpen, toggleSidebar} = useLayout()
  const [dropdownIsOpen, toggleDropdown] = useDropdown()

  const logoutParam = useQuery('logout')

  useMemo(async () => {
    if(logoutParam === 'true') {
      await logout()
      await swal('Success', 'You need to login again', 'success')
    }
  }, [logoutParam])


  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  useEffect(() => {
    dispatch(fetchTasksMetaData(currentUser.uid))
    dispatch(fetchMonthStats(currentUser.uid))
  }, [])

  const getRoutes = (routes) => {
    return routes.map((route, key) => (<Route key={key} exact path={route.path} component={route.component}/>))
  };

  const notifications = useSelector(selectNotifications)


  return (
    <>
      <CreateTask />
      <div className="main-content" ref={mainContent}>
        <div className="app-overlay"/>
        <Navbar />

        <div className={`view-wrapper ${sidebarIsOpen && 'is-pushed-full'}`} data-naver-offset="214" data-menu-item="#layouts-sidebar-menu"
             data-mobile-item="#home-sidebar-menu-mobile">

          <div className="page-content-wrapper">
            <div className={`page-content is-relative ${!subscriptionIsActivated() && location.pathname !== '/subscriptions' && 'is-disabled'}`}>

              <div className="page-title has-text-centered">
                <div className={`huro-hamburger nav-trigger push-resize ${activeSidebarClass()}`} data-sidebar="layouts-sidebar">
                    <span className="menu-toggle has-chevron">
                        <span className={`icon-box-toggle ${sidebarIsOpen && 'active'}`} onClick={toggleSidebar}>
                            <span className="rotate">
                                <i className="icon-line-top"/>
                                <i className="icon-line-center"/>
                                <i className="icon-line-bottom"/>
                            </span>
                        </span>
                    </span>
                </div>

                <div className="title-wrap">
                  <h1 className="title is-4">Grafix Shop</h1>
                </div>
                <div className="toolbar ml-auto">

                  <div className="toolbar-link">
                    <label className="dark-mode ml-auto">
                      <input type="checkbox" onClick={switchTheme} defaultChecked={isLightTheme()} />
                      <span/>
                    </label>
                  </div>

                  <div className="toolbar-notifications is-hidden-mobile">
                    <div className={`dropdown is-spaced is-dots is-right dropdown-trigger ${dropdownIsOpen && 'is-active'}`}>
                      <div className="is-trigger" aria-haspopup="true" onClick={toggleDropdown}>
                        <i className="far fa-bell"/>
                        {notifications.length !== 0 &&
                          <span className="new-indicator pulsate"/>}
                      </div>
                      <div className="dropdown-menu" role="menu">
                        <div className="dropdown-content">
                          <div className="heading">
                            <div className="heading-left">
                              <h6 className="heading-title">Notifications</h6>
                            </div>
                            <div className="heading-right">
                              <a className="notification-link" href="#"
                                 onClick={() => dispatch(clearNotifications(currentUser.uid))}>
                                Clear all
                              </a>
                            </div>
                          </div>
                          <ul className="notification-list">
                            {notifications?.map((notification, index) => (
                              <li key={index}>
                                <Link className="notification-item" to={notification.route || '#'}>
                                  <div className="user-content">
                                    <p className="user-info"><span className="name">{notification.title}</span></p>
                                    <p className="user-info">{notification.content}</p>
                                    <p className="time">{moment(notification.created_at, "YYYYMMDD").fromNow()}</p>
                                  </div>
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* TODO: this can be used later */}
                  {/*<a className="toolbar-link right-panel-trigger" data-panel="activity-panel" href="/">*/}
                  {/*  <i className="bi bi-grid"/>*/}
                  {/*</a>*/}
                </div>
              </div>

              {/* Routes */}

              <Switch>
                {getRoutes(routes)}
                <Redirect from="*" to={`/`} />
              </Switch>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainLayout;
