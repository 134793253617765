import {useLayout} from "contexts/LayoutContext";
import FeatherIcon from 'feather-icons-react';
import {Link} from "react-router-dom";
import useDropdown from "hooks/useDropdown";

export default function CreateTaskButton({className = 'icon-button', content, dropdownEnabled = false}) {
  const [dropdownIsOpen, toggleDropdown] = useDropdown()

  const {toggleModal} = useLayout()

  function renderContent() {
    return content || <i className="bi bi-plus-lg"/>
  }

  function handleClick() {
    if (dropdownEnabled) {
      toggleDropdown()
    } else {
      renderForm()
    }
  }

  function renderForm() {
    toggleModal('createTask')
    toggleDropdown()
  }

  return(
    <div className={`dropdown is-modern is-spaced dropdown-trigger ${dropdownIsOpen && 'is-active'}`}>
      <button className={className} onClick={handleClick}>
        {renderContent()}
        {dropdownEnabled && <FeatherIcon icon={'chevron-down'} />}
      </button>
      <div className="dropdown-menu" role="menu">
        <div className="dropdown-content">
          <a href="#" className="dropdown-item is-media" onClick={() => renderForm()}>
            <div className="icon">
              <i className="lnil lnil-plus"/>
            </div>
            <div className="meta">
              <span>Create New</span>
              <span>Brand new task</span>
            </div>
          </a>
          <Link to={`templatesCategories`} className="dropdown-item is-media">
            <div className="icon">
              <i className="lnil lnil-star"/>
            </div>
            <div className="meta">
              <span>Create from a Template</span>
              <span>Pick from template</span>
            </div>
          </Link>
        </div>
      </div>
    </div>
  )
}
