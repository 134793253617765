import {createSlice} from '@reduxjs/toolkit'
import planConverter from 'converters/planConverter';
import {firestore} from "firebase.js";

// noinspection ES6CheckImport
import {collection, onSnapshot, query, where} from "firebase/firestore";


// =========================== Slice ===========================
export const planSlice = createSlice({
  name: 'plan',
  initialState: {
    plans: []
  },
  reducers: {
    set: (state, action) => {
      state.plans = action.payload
    }
  }
})

// ============================= Actions =============================
export const {set} = planSlice.actions;

// ============================= Thunks =============================
export const fetchAllPlans = (clientId, filters = []) => async dispatch => {
  filters.push(where('client_id', '==', clientId))
  const q = query(collection(firestore, 'plans').withConverter(planConverter), ...filters)
  onSnapshot(q, (querySnapshot) => {
    const plans = [];
    querySnapshot.forEach((doc) => {
      plans.push({...doc.data(), ...{id: doc.id}})
    });
    dispatch(set(plans))
  })
};

// ============================ Selectors ============================
export const selectPlans = state => state.plan.plans;


// =========================== Default Export =========================
export default planSlice.reducer
