import {configureStore} from '@reduxjs/toolkit'
import notificationReducer from 'slices/notificationSlice';
import planReducer from 'slices/planSlice';
import taskReducer from "slices/taskSlice";
import templatesCategoryReducer from 'slices/templatesCategorySlice';

export default configureStore({
  reducer: {
    task: taskReducer,
    notification: notificationReducer,
    templatesCategory: templatesCategoryReducer,
    plan: planReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ['task/set', 'task/setTemplates', 'task/setMetadata', 'task/setMonthStats',
          'notification/set', 'templatesCategory/set', 'plan/set'],
        // Ignore these field paths in all actions
        ignoredActionPaths: [],
        // Ignore these paths in the state
        ignoredPaths: []
      },
    }),
})
