import Calendar from 'components/Calendar';
import {useAuth} from 'contexts/AuthContext';
import selectResource from 'hooks/selectResource';
import moment from 'moment';
import {useState} from 'react';
import {fetchAllPlans, selectPlans} from 'slices/planSlice';
import CreatePlan from 'views/plans/CreatePlan';
import EditPlan from 'views/plans/EditPlan';
import CreateModal from 'views/tasks/CreateModal';

export default function PlansIndex() {

  const [createPlansIsOpen, setCreatePlansIsOpen] = useState(false)
  const [editPlansIsOpen, setEditPlansIsOpen] = useState(false)
  const [defaultDateRange, setDefaultDateRange] = useState({
    start: null,
    end: null
  })
  const [createTaskIsOpen, setCreateTaskIsOpen] = useState(false)
  const [selectedPlan, setSelectedPlan] = useState({})
  const {currentUser} = useAuth()

  const onSelectSlot = (start, end) => {
    setDefaultDateRange({start, end})
    setCreatePlansIsOpen(true)
  }

  const onSelectEvent = (event) => {
    setSelectedPlan(event)
    setEditPlansIsOpen(true)
  }

  const planToTask = () => {
    const {id, start, end, ...task} = {
      ...selectedPlan,
      ...{creation_date: moment(selectedPlan.start).startOf('month').toDate()}
    }
    return task;
  }

  const plans = selectResource(fetchAllPlans(currentUser.uid), selectPlans)

  return (
    <div className="page-content-inner">
      <div style={{height: '80vh', borderRadius: '5px'}}>
        <Calendar
          events={plans}
          onSelectSlot={onSelectSlot}
          onSelectEvent={onSelectEvent}
        />
      </div>
      {createPlansIsOpen && <CreatePlan toggleIsOpen={setCreatePlansIsOpen}
                                        defaultStartDate={defaultDateRange.start}
                                        defaultEndDate={defaultDateRange.end}
      />}
      {editPlansIsOpen && <EditPlan toggleIsOpen={setEditPlansIsOpen}
                                    plan={selectedPlan} setCreateTaskIsOpen={setCreateTaskIsOpen}/>}
      {createTaskIsOpen && <CreateModal templateTask={planToTask()} toggleIsOpen={() => setCreateTaskIsOpen(false)}/>}
    </div>
  )
}
