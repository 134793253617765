import {forwardRef} from 'react';
import BaseDatePicker from "react-datepicker"

export default function DatePicker({selectedDate, onChange}) {

  const CustomButton = forwardRef(({ value, onClick }, ref) => (
    <button className="button h-button is-raised is-primary is-outlined" style={{width: '10rem'}} onClick={onClick} ref={ref}>
      {value|| 'Press to pick a date'}
    </button>
  ));

  return (
    <BaseDatePicker
      selected={selectedDate}
      onChange={onChange}
      customInput={<CustomButton />}
      dateFormat={'MMMM - yyyy'}
      showMonthYearPicker
    />
  )
}
