import header from "assets/img/illustrations/projects/header.svg";
import headerDark from "assets/img/illustrations/projects/header-dark.svg";
import CreateTaskButton from "components/CreateTaskButton";
import {selectMonthStats} from "slices/taskSlice";
import {useSelector} from "react-redux";
import Task from "models/Task";

export default function TasksStatsHeader() {
  const stats = useSelector(selectMonthStats)

  return(
    <>
      <div className="all-projects">
        <div className="illustration-header">
          <img className="light-image" src={header} alt="" />
          <img className="dark-image" src={headerDark} alt="" />
          <div className="header-stats">
            <div className="stats-inner is-justify-content-space-around">
              <div className="stat-item">
                <span className="is-success">{stats[Task.STATUS.IN_PROGRESS]}</span>
                <p>In Progress</p>
              </div>
              <div className="stat-item">
                <span className="is-warning">{stats[Task.STATUS.PENDING]}</span>
                <p>Pending</p>
              </div>
              <div className="stat-item">
                <span className="is-info">{stats[Task.STATUS.DONE]}</span>
                <p>Done</p>
              </div>
              <div className="stat-item">
                <span className="is-primary">{stats.total}</span>
                <p>Tasks Total</p>
              </div>
              <div className="stat-item">
                <CreateTaskButton className="button h-button is-primary" content="Add Task" dropdownEnabled={true}/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br/>
    </>
  )
}
