// =========================================== Imports ===========================================
import algoliasearch from "algoliasearch";
import DatePicker from "components/DatePicker"
import DropdownMenu from "components/DropdownMenu";
import TasksStatsHeader from "components/TasksStatsHeader"
import {useAuth} from "contexts/AuthContext";
import FeatherIcon from "feather-icons-react";
import {orderBy, where} from 'firebase/firestore';
import Task from "models/Task";
import moment from 'moment';
import {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from 'react-redux'
import {useLocation} from "react-router-dom";
import {fetchTasks, searchTasksByIds, selectTasksFiltered} from "slices/taskSlice";
import "views/custom.scss"
import KanbanCard from "views/tasks/KanbanCard";
import KanbanColumn from "views/tasks/KanbanColumn";
import TaskRow from 'views/tasks/TaskRow';

const client = algoliasearch(process.env.REACT_APP_ALGOLIA_SEARCH_APP_ID, process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY)
const index = client.initIndex('GrafixShop');
// =====================================================================


export default function Index() {
  const [activeView, setActiveView] = useState('kanban')

  let filteredTasks = useSelector(selectTasksFiltered)

  return(
    <div className="page-content-inner">

      <TasksStatsHeader />

      {/*Toolbar*/}
      <div className="kanban-toolbar columns is-vcentered is-multiline">

        <Filters statusFilteringIsActive={activeView === 'table'} />

        <div className="field has-addons is-flex is-justify-content-center column is-one-fifth">
          <p className="control">
            <button className={`button h-button is-raised ${activeView === 'kanban' && 'is-primary'}`}
                    onClick={() => setActiveView('kanban')}>
              <span className="icon is-small">
                <i className="fas fa-columns"/>
              </span>
              <span>Kanban</span>
            </button>
          </p>
          <p className="control">
            <button className={`button h-button is-raised ${activeView === 'table' && 'is-primary'}`}
                    onClick={() => setActiveView('table')}>
              <span className="icon is-small">
                <i className="fas fa-list"/>
              </span>
              <span>Table</span>
            </button>
          </p>
        </div>
      </div>

      {
        activeView === 'kanban' ?
          <KanbanView filteredTasks={filteredTasks} KanbanCard={KanbanCard} /> :
          <TableView Task={Task} tasks={filteredTasks?.All} />
      }
    </div>
  )
}

function TableView({tasks}) {

  return(
    <div className="columns tasks-table">
      <div className="column is-12">
        <div className="s-card">
          <table className="table is-hoverable is-fullwidth">
            <tbody>
            <tr>
              <th style={{width: '7%'}}>Task ID</th>
              <th style={{width: '11%'}}>Creation Date</th>
              <th style={{width: '30%'}}>Title</th>
              <th style={{width: '40%'}}>Tasks Content</th>
              <th style={{width: '9%'}}>Status</th>
              <th style={{width: '3%'}} className="is-end">
                <div className="dark-inverted">
                  Actions
                </div>
              </th>
            </tr>
            { tasks?.map( (task, index) => <TaskRow key={index} task={task} />) }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

function KanbanView({filteredTasks, KanbanCard}) {

  return(
    <div className="columns is-kanban-wrapper">
      <KanbanColumn
        title={'Pending'} tasks={filteredTasks[Task.STATUS.PENDING]}
        emptyImg={require('assets/img/illustrations/projects/board/new-dark.svg').default}
        emptyText={'There are no pending tasks.'}
        KanbanCard={KanbanCard}
        isInitial={true}
      />

      <KanbanColumn
        title={'Missing Information'}
        tasks={filteredTasks[Task.STATUS.MISSING_INFORMATION]}
        emptyImg={require('assets/img/illustrations/projects/board/test-dark.svg').default}
        emptyText={'There are no tasks missing information.'}
        KanbanCard={KanbanCard}
      />

      <KanbanColumn
        title={'Approved'} tasks={filteredTasks[Task.STATUS.APPROVED]}
        emptyImg={require('assets/img/illustrations/projects/board/ready-dark.svg').default}
        emptyText={'There are no approved tasks.'}
        KanbanCard={KanbanCard}
      />

      <KanbanColumn
        title={'In Progress'} tasks={filteredTasks[Task.STATUS.IN_PROGRESS]}
        emptyImg={require('assets/img/illustrations/projects/board/progress.svg').default}
        emptyText={'There are no tasks in progress at the moment.'}
        KanbanCard={KanbanCard}
      />

      <KanbanColumn
        title={'Uploaded'} tasks={filteredTasks[Task.STATUS.UPLOADED]}
        emptyImg={require('assets/img/illustrations/projects/board/ready-dark.svg').default}
        emptyText={'There are no uploaded tasks at the moment.'}
        KanbanCard={KanbanCard}
      />

      <KanbanColumn
        title={'Done'} tasks={filteredTasks[Task.STATUS.DONE]}
        emptyImg={require('assets/img/illustrations/projects/board/complete-dark.svg').default}
        emptyText={'There are no uploaded tasks at the moment.'}
        KanbanCard={KanbanCard}
      />
    </div>
  )
}

function Filters({statusFilteringIsActive}) {

  const dispatch = useDispatch()
  const {currentUser} = useAuth()
  const searchTermRef = useRef()
  const statuses = Task.MainStatusArray
  let {state} = useLocation()

  const [filters, setFilters] = useState({
    creation_date: state?.date || moment().startOf('month').toDate(),
    default: !state?.date,
    status: null,
    leftovers: false
  })

  useEffect(() => {
    const filtersArray = [];
    if (filters.leftovers) {
      filtersArray.push(where("creation_date", '<', moment().startOf('month').toDate()))
      filtersArray.push(where('status', 'in', [Task.STATUS.PENDING, Task.STATUS.MISSING_INFORMATION]))
      filtersArray.push(orderBy('creation_date', 'desc'))
    } else if (filters.default) {
      filtersArray.push(where("creation_date", '>=', filters.creation_date))
      filtersArray.push(orderBy('creation_date', 'desc'))
    } else {
      if (filters.status) {
        filtersArray.push(where('status', '==', filters.status))
      }
      if (filters.creation_date) {
        filtersArray.push(where("creation_date", '>=', filters.creation_date))
        filtersArray.push(where('creation_date', '<',
          moment(filters.creation_date).add(1, 'M').startOf().toDate()))
        filtersArray.push(orderBy('creation_date', 'desc'))
      }
    }
    dispatch(fetchTasks(currentUser.uid, filtersArray))
  }, [filters])

  useEffect(() => {
    setFilters({...filters, ...{status: null}})
  }, [statusFilteringIsActive])


  function handleSubmit(e) {
    e.preventDefault()
    const searchTerm = searchTermRef.current["value"];
    if(searchTerm === '') {
      dispatch(fetchTasks(currentUser.uid))
    } else {
      index.search(searchTerm, {
        attributesToRetrieve: ['objectID'],
        hitsPerPage: 50
      }).then(({hits}) => {
        const ids = hits.map((hit) => hit.objectID)
        dispatch(searchTasksByIds(ids, currentUser.uid))
      })
    }
  }

  function setDefault() {
    setFilters({
      creation_date: moment().startOf('month').toDate(),
      status: null,
      leftovers: false,
      default: true
    })
  }

  return(
    <>
      <div className="column is-flex is-one-fifth">
        <div className="control has-icon" style={{minWidth: '212px'}}>
          <form onSubmit={handleSubmit}>
            <input className="input" placeholder="Search..." ref={searchTermRef} />
          </form>
          <a className="form-icon" href='#' onClick={handleSubmit}>
            <FeatherIcon icon={'search'} />
          </a>
        </div>
      </div>
      <div className="column is-three-fifths is-flex-inline is-flex-wrap-wrap has-gaps is-justify-content-center">
        <DatePicker
          selectedDate={filters.creation_date}
          onChange={(creation_date) => setFilters({...filters, ...{creation_date, leftovers: false, default: false}})}
        />
        {statusFilteringIsActive && <DropdownMenu
          title="Status"
          selectedOption={filters.status} options={statuses} color="primary"
          setActive={(status) => setFilters({...filters, ...{status}})}/>}
        <button className="button h-button is-primary is-raised is-outlined"
                onClick={() => setFilters({leftovers: true})}>
          Previous Pending Tasks
        </button>
        <button className="button h-button is-danger is-raised" onClick={() => setDefault()}>
          Default
        </button>
      </div>
    </>
  )
}
