import {useAuth} from 'contexts/AuthContext';
import subscriptionConverter from 'converters/subscriptionConverter';
import {firestore} from 'firebase.js';
import {collection, getDocs, getDoc, addDoc, query, where, onSnapshot} from 'firebase/firestore';
import {useMemo, useState} from 'react';
import {useFirestore} from 'reactfire';

export function getProducts() {
  // Hooks
  const firestore = useFirestore()

  // States
  const [products, setProducts] = useState([])

  // Effects
  useMemo( async () => {
    const q = query(collection(firestore, 'products'), where('active','==', true))
    const productsQuerySnapshot = await getDocs(q)
    const productsArr = [];
    productsQuerySnapshot.forEach((doc) => {
      productsArr.push({...doc.data(), ...{id: doc.id}})
    })
    setProducts(productsArr)
  }, [])

  return products;
}

export function getPrices(productID) {
  // Hooks
  const firestore = useFirestore()

  // States
  const [prices, setPrices] = useState([])

  useMemo(async () => {
    const q = query(collection(firestore, `products/${productID}/prices`),
      where('active','==', true))
    const querySnapshot = await getDocs(q)
    const pricesArr = [];
    querySnapshot.forEach( (doc) => {
      pricesArr.push({...doc.data(), ...{id: doc.id}})
    })
    // Sort alphabetically
    pricesArr.sort((a, b) => a.interval?.localeCompare(b.interval));
    setPrices(pricesArr)
  }, [])

  return prices;
}

export async function startCheckoutSession(userId, price) {
  const docRef = await addDoc(collection(firestore, `users/${userId}/checkout_sessions`), {...{
      price: price.id,
      allow_promotion_codes: true,
      success_url: `${window.location.origin}?logout=true`,
      cancel_url: window.location.origin
    }, ...(price.type === 'one_time') && {mode: 'payment'}});
  onSnapshot(docRef, (snap) => {
    const { error, url } = snap.data();
    // Catch error
    if(error) alert(`An error occurred: ${error.message}`);
    // Redirect to the url
    if(url) window.location.assign(url);
  })
}

export function getSubscriptions() {
  const {currentUser} = useAuth()
  // States
  const [subscriptions, setSubscriptions] = useState([])

  useMemo(async () => {
    const q = query(query(collection(firestore, `users/${currentUser.uid}/subscriptions`)
      .withConverter(subscriptionConverter)))
    const querySnapshot = await getDocs(q);
    const subscriptionsArr = [];
    querySnapshot.forEach((doc) => {
      const subscriptionData = doc.data()
      subscriptionsArr.push({...subscriptionData, ...{id: doc.id}})
    })
    for await (const subscription of subscriptionsArr) {
      const productSnap = await getDoc(subscription.product);
      subscription.product = productSnap.data()
    }
    setSubscriptions(subscriptionsArr)
  }, [])

  return subscriptions;
}

export async function getPayments(userId) {
  const querySnapshot  = await getDocs(collection(firestore, `users/${userId}/payments`))
  const payments = [];
  querySnapshot.forEach( doc => {
    payments.push(doc.data())
  })
  return payments;
}
