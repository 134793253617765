import CircleChart from "components/CircleChart";
import TasksStatsHeader from "components/TasksStatsHeader";
import {useAuth} from 'contexts/AuthContext';
import {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import constants from "constants.js";
import {useDispatch} from "react-redux";
import {fetchCalendarData} from "slices/taskSlice";
import moment from 'moment';


export default function Calendar() {

  // TODO: make this dynamic
  const currentYear = 2022;
  const dispatch = useDispatch()
  const {currentUser} = useAuth()
  const [stats, setStats] = useState({})

  useEffect(() => {
    const fetchData = async () => (await dispatch(fetchCalendarData(currentUser.uid)))
    fetchData()
      .then(fetchedCalendarData => {
        setStats(fetchedCalendarData)
      })
  }, [])

  return(
    <div className="page-content-inner all-projects">

      <TasksStatsHeader isAdmin={false}/>

      <div className="recent-projects">
        <h1 className="recent-heading">Calender</h1>

        <div className="columns">
          <MonthBox currentYear={currentYear} monthNumber={1} stats={stats} />
          <MonthBox currentYear={currentYear} monthNumber={2} stats={stats} />
          <MonthBox currentYear={currentYear} monthNumber={3} stats={stats} />
        </div>

        <div className="columns">
          <MonthBox currentYear={currentYear} monthNumber={4} stats={stats} />
          <MonthBox currentYear={currentYear} monthNumber={5} stats={stats} />
          <MonthBox currentYear={currentYear} monthNumber={6} stats={stats} />
        </div>

        <div className="columns">
          <MonthBox currentYear={currentYear} monthNumber={7} stats={stats} />
          <MonthBox currentYear={currentYear} monthNumber={8} stats={stats} />
          <MonthBox currentYear={currentYear} monthNumber={9} stats={stats} />
        </div>

        <div className="columns">
          <MonthBox currentYear={currentYear} monthNumber={10} stats={stats} />
          <MonthBox currentYear={currentYear} monthNumber={11} stats={stats} />
          <MonthBox currentYear={currentYear} monthNumber={12} stats={stats} />
        </div>

        <h3 className="has-text-centered">
          The colors represent the percentage of done tasks
        </h3>

        <div className="is-flex is-flex-wrap-wrap	is-justify-content-space-evenly">

          <label className="radio is-solid is-danger">
            <input type="radio" name="solid_radio" readOnly={true} checked={false} />
            <span/>
            0-10%
          </label>

          <label className="radio is-solid is-warning">
            <input type="radio" name="solid_radio" readOnly={true} checked={false} />
            <span/>
            10-40%
          </label>

          <label className="radio is-solid is-primary">
            <input type="radio" name="solid_radio" readOnly={true} checked={false} />
            <span/>
            40-70%
          </label>

          <label className="radio is-solid is-success">
            <input type="radio" name="solid_radio" readOnly={true} checked={false} />
            <span/>
            70-100%
          </label>
        </div>

      </div>

    </div>
  )
}


function MonthBox({currentYear, monthNumber, stats}) {

  const monthStats = stats?.[constants.MONTHS[monthNumber-1]];
  const percentage = (monthStats?.['Done'] / monthStats?.['Total']) * 100;
  const date = moment().month(monthNumber -1).startOf('month').toDate();

  return (
    <div className={`column is-4 ${monthStats?.['Total'] === 0 && 'is-disabled' }`}>
      <Link className="project-box" to={{pathname: `/tasks`, state: {date}}}>
        <CircleChart percentage={percentage || 0} />
        <h3>{constants.MONTHS[monthNumber - 1]} - {currentYear}</h3>
      </Link>
    </div>
  )
}
